export const PERMISSIONTYPES = [
  {
    label: "Application",
    value: "application",
  },
  {
    label: "API",
    value: "api",
  },
  {
    label: "UI",
    value: "ui",
  },
];

export const ACCESSTYPES = [
  {
    label: "Read",
    value: "read",
  },
  {
    label: "Create",
    value: "create",
  },
  {
    label: "Update",
    value: "update",
  },
  {
    label: "Delete",
    value: "delete",
  },
];
