import React from "react";
import "./styles.css";
import Button from "@kaizen-ui/button";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
export const Header = ({
  headerHeading,
  loginHeader = true,
  dashboardHeader = false,
}) => {
  //user
  const navigate = useNavigate();
  return (
    <div className="navigation-enterprise">
      <div className="header-container">
        <div className="header-content">
          <div className="main-links">
            <div className="group-38">
              <div className="header-logo">
                <div className="header-logo">
                  <Link to="/dashboard">
                    <svg
                      className="eye-mark"
                      width="31"
                      height="22"
                      viewBox="0 0 31 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.8791 6.90854V5.0772C12.0555 5.06479 12.2331 5.05463 12.414 5.04899C17.3726 4.89102 20.6259 9.3537 20.6259 9.3537C20.6259 9.3537 17.1124 14.2847 13.3454 14.2847C12.8027 14.2847 12.3169 14.1966 11.8791 14.0477V8.49276C13.81 8.72859 14.1976 9.58953 15.3579 11.5439L17.9388 9.34467C17.9388 9.34467 16.0548 6.8476 12.8786 6.8476C12.5335 6.8476 12.2029 6.87243 11.878 6.90741L11.8791 6.90854ZM11.878 0.857117V3.5934C12.0555 3.57873 12.2342 3.56745 12.4129 3.56068C19.308 3.32598 23.8009 9.27472 23.8009 9.27472C23.8009 9.27472 18.6413 15.6139 13.265 15.6139C12.7725 15.6139 12.3113 15.5676 11.878 15.4909V17.1823C12.2487 17.2297 12.6329 17.2579 13.0327 17.2579C18.0349 17.2579 21.6533 14.6773 25.1567 11.6217C25.7375 12.0911 28.1152 13.2341 28.6043 13.7351C25.2729 16.5527 17.5111 18.8229 13.1098 18.8229C12.6854 18.8229 12.2778 18.797 11.878 18.7586V21.135H30.8915V0.858245H11.8791L11.878 0.857117ZM11.878 14.0466V15.4909C7.25104 14.657 5.96671 9.79828 5.96671 9.79828C5.96671 9.79828 8.18803 7.31136 11.878 6.90854V8.49276C11.878 8.49276 11.8735 8.49276 11.8713 8.49276C9.93471 8.25806 8.42256 10.086 8.42256 10.086C8.42256 10.086 9.27022 13.1631 11.8791 14.0488L11.878 14.0466ZM3.66163 9.58953C3.66163 9.58953 6.40338 5.50147 11.8791 5.07833V3.59566C5.81483 4.08762 0.5625 9.27697 0.5625 9.27697C0.5625 9.27697 3.53655 17.9654 11.8791 18.7609V17.1845C5.75675 16.406 3.66163 9.58953 3.66163 9.58953Z"
                        fill="#74B71B"
                      />
                    </svg>
                    <svg
                      className="wordmark"
                      width="75"
                      height="15"
                      viewBox="0 0 75 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M73.0112 13.1214V12.8178H73.2044C73.3093 12.8178 73.4534 12.8257 73.4534 12.9555C73.4534 13.0965 73.3797 13.1214 73.2546 13.1214H73.0112ZM73.0112 13.3346H73.1407L73.44 13.8661H73.7683L73.4378 13.3132C73.6086 13.3008 73.7494 13.2184 73.7494 12.9848C73.7494 12.696 73.5517 12.6023 73.2178 12.6023H72.7342V13.865H73.0123V13.3335L73.0112 13.3346ZM74.4183 13.2342C74.4183 12.4929 73.8476 12.0618 73.2122 12.0618C72.5767 12.0618 72.0027 12.4917 72.0027 13.2342C72.0027 13.9767 72.5723 14.4077 73.2122 14.4077C73.8521 14.4077 74.4183 13.9755 74.4183 13.2342ZM74.0699 13.2342C74.0699 13.7747 73.6768 14.1369 73.2122 14.1369V14.1335C72.7342 14.1369 72.3489 13.7747 72.3489 13.2342C72.3489 12.6937 72.7353 12.3326 73.2122 12.3326C73.6891 12.3326 74.0699 12.6937 74.0699 13.2342Z"
                        fill="black"
                      />
                      <path
                        d="M30.1405 0.690173V14.0004H33.8617V0.690173H30.1405ZM0.873535 0.672119V14.0004H4.62711V3.65439L7.55537 3.66454C8.51806 3.66454 9.18479 3.89811 9.64826 4.39798C10.2368 5.03212 10.4769 6.05329 10.4769 7.92186V14.0004H14.1132V6.63665C14.1132 1.38073 10.7975 0.672119 7.55425 0.672119H0.873535ZM36.131 0.690173V13.9992H42.1651C45.3804 13.9992 46.4291 13.4587 47.5648 12.248C48.3667 11.3972 48.8849 9.53091 48.8849 7.49083C48.8849 5.62 48.446 3.95115 47.681 2.91192C46.3029 1.05351 44.3172 0.690173 41.3521 0.690173H36.131ZM39.8209 3.58781H41.4202C43.7409 3.58781 45.2419 4.64058 45.2419 7.37235C45.2419 10.1041 43.7409 11.158 41.4202 11.158H39.8209V3.58781ZM24.7765 0.690173L21.6718 11.2381L18.6966 0.690173H14.6806L18.93 13.9992H24.2918L28.5747 0.690173H24.7776H24.7765ZM50.616 13.9992H54.3371V0.690173H50.6148V13.9992H50.616ZM61.0458 0.694686L55.8504 13.9947H59.5191L60.3411 11.6443H66.4891L67.2675 13.9947H71.25L66.0156 0.693558H61.0458V0.694686ZM63.4603 3.1218L65.714 9.35263H61.1351L63.4603 3.1218Z"
                        fill="black"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loginHeader && (
        <div className="brand-navigation">
          <div className="brand-nav">
            <div className="header-container2">
              <div className="header-frame-39">
                <div className="header-site-name">{headerHeading} </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      )}
      {dashboardHeader && (
        <div className="brand-navigation">
          <div className="brand-nav">
            <div className="header-container2">
              <div className="header-frame-39">
                <div className="dashboard-header-site-name">
                  {headerHeading}{" "}
                </div>
              </div>
              <div className="header-button-container">
                {/* <div className="dashboard-header-frame-1"> */}

                <button
                  class="dashboard-header-primary dashboard-header-frame-1"
                  type="submit"
                  onClick={() => {
                    navigate("/logout");
                  }}
                >
                  Log Out
                </button>
                {/* <div className="dashboard-header-primary">Log Out </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
