import "./styles.css";
import Button from "@kaizen-ui/button";
import React, { useEffect } from "react";

export default function UnAuthorizedError() {
  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
  }, []);
  // const navigate = useNavigate();
  return (
    <div className="unauthorized-page-container">
      <div className="unauthorized-page-content">
        <div
          style={{
            dispplay: "flex",
            justifyContent: "space-around",
            flexDirection: "row",
            marginBottom: "20px",
          }}
        >
          <p style={{ fontSize: "40px", margin: "0px", fontWeight: "bold" }}>
            <span style={{ fontSize: "40px", color: "red" }}>401</span>{" "}
            UNAUTHORIZED
          </p>
          <p style={{ marginTop: "0px" }}>
            You are not authorized to view this page!
          </p>
        </div>
        <Button
          key="btncreateresponse"
          style={{ width: "200px" }}
          onClick={() => {
            window.location.href = "/login";
          }}
        >
          Login
        </Button>
      </div>
    </div>
  );
}
