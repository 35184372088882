import React, { useContext, useCallback, useEffect, useState } from "react";
import Header from "../../components/header";
import GlobalFooter from "../../components/globalfooter";
import NeedHelpAccordion from "../../components/needhelpaccordion";
import "./styles.css";
const NeedHelp = () => {
  return (
    <div>
      <Header
        headerHeading="NVIDIA Application Hub FAQ"
        loginHeader={true}
        dashboardHeader={false}
      ></Header>
      <NeedHelpAccordion></NeedHelpAccordion>
      <GlobalFooter></GlobalFooter>
    </div>
  );
};
export default NeedHelp;
