import { useContext, useEffect, useState } from "react";
import Spinner from "@kaizen-ui/spinner";
import {
  fetchApplication,
  postApplication,
  patchApplication,
  deleteApplication,
} from "../../../api";
import { Layout } from "../../../layout";
import { useParams } from "react-router-dom";
import { ErrorMessage, Field, Formik } from "formik";
import Block from "@kaizen-ui/block";
import Button from "@kaizen-ui/button";
import Textbox from "@kaizen-ui/textbox";
import "./styles.css";
import { BlockSection } from "@kaizen-ui/block";
import ButtonGroup from "@kaizen-ui/button";
import "react-datepicker/dist/react-datepicker.css";

import { useNavigate } from "react-router-dom";
import { string, object } from "yup";
import "./styles.css";
import { Banner, KaizenThemeContext } from "@kaizen-ui/complete";
import WithAuth from "../../../components/auth/WithAuth";
import WithAdminUIAuth from "../../../components/auth/WithAdminUIAuth";
import AccessibilityContext from "../../../components/auth/UserPermissionContext";
const Loading = () => (
  <Layout title="Loading...">
    <Spinner className="loading-spinner" size="small" />
  </Layout>
);

const initstate = {
  applicationid: 0,
  applicationname: "",
  applicationurl: "",
  applicationdescription: "",
  isactive: true,
  createddate: new Date(),
  modifieddate: new Date(),
  modifiedby: "",
  createdby: "",
};
const validationSchema = object().shape({
  applicationname: string().required("Application Name is required"),
  applicationurl: string()
    //.matches(regMatch, "Application should be a valid URL")
    .required("Application URL is required"),
  //applicationurl: string().matches("Application should be a valid URL"),
  applicationdescription: string().required(
    "Application Description is required."
  ),
});

const ApplicationDetail = () => {
  let { id } = useParams();
  const [applicationdata, setData] = useState();
  const [error, setError] = useState();
  const [errormsg, setErrorMsg] = useState("");
  const [initialValues] = useState(initstate);
  const [success, setSuccess] = useState("");
  const { colors } = useContext(KaizenThemeContext);

  const permissions = useContext(AccessibilityContext).filter(
    (x) => x.pagename === "application"
  )[0];

  useEffect(() => {
    if (id === "0") {
      setData({
        applicationid: 0,
        applicationname: "",
        applicationurl: "",
        applicationdescription: "",
        applicationicon: "",
      });
    } else {
      fetchApplication(Number(id)).then((data) => {
        setData(data);
      });
    }
  }, [id]);

  function errorHandler(error, setError, setErrorMsg) {
    setErrorMsg(error.status_message);
    setError(error);
    setTimeout(() => {
      setError(undefined);
    }, 5000);
  }
  function SuccessHandler(setSuccess, setData, data, navigate) {
    setTimeout(() => {
      setSuccess("");
      if (data !== undefined) {
        setData(data);
        navigate("/application");
        // navigate("/application/" + data.applicationid);
        // id = data.applicationid.toString();
      } else {
        navigate("/application");
      }
    }, 1000);
  }

  const disableDelete = () => {
    if (applicationdata && permissions?.delete) {
      if (applicationdata.applicationid > 0) return false;
    }
    return true;
  };

  const disableEdit = () => {
    return !(permissions?.create || (permissions?.update && id > 0));
  };
  const deleteApplications = () => {
    if (id !== undefined) {
      var bconfirm = window.confirm("Do you want to delete the Application?");
      if (bconfirm) {
        deleteApplication(parseInt(id))
          .then((data) => {
            setSuccess("Successfully deleted application - " + id);
            SuccessHandler(setSuccess, undefined, undefined, navigate);
          })
          .catch((error) => {
            errorHandler(error, setError, setErrorMsg);
          })
          .finally(() => {});
      }
    }
  };

  const navigate = useNavigate();
  if (!applicationdata) return <Loading />;
  if (permissions != null && permissions !== undefined && !permissions.read) {
    navigate("/unauthorized");
  }

  if (permissions?.read) {
    return (
      <Formik
        initialValues={
          applicationdata !== null ? applicationdata : initialValues
        }
        initialTouched={{
          applicationid: true,
          applicationname: true,
          applicationurl: true,
          applicationdescription: true,
          applicationicon: true,
        }}
        onReset={(values) => {
          values = applicationdata;
        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          // buildrequest(values)
          if (id === "0") {
            const createdata = values;
            setError(undefined);
            postApplication(createdata)
              .then((data) => {
                setSuccess(
                  "Successfully created Application - " + data.applicationname
                );
                SuccessHandler(setSuccess, setData, data, navigate);
              })
              .catch((error) => {
                errorHandler(error, setError, setErrorMsg);
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          } else {
            const updatedata = values;
            patchApplication(updatedata)
              .then((data) => {
                setSuccess(
                  "Successfully updated Application - " + data.applicationname
                );
                SuccessHandler(setSuccess, setData, data, navigate);
              })
              .catch((error) => {
                errorHandler(error, setError, setErrorMsg);
              })
              .finally(() => {
                actions.resetForm();
                actions.setSubmitting(false);
              });
          }
        }}
        validationSchema={validationSchema}
        validateOnMount
      >
        {(formikProps) => (
          <Layout
            title="Application"
            headerChildren={
              <ButtonGroup>
                <Button
                  icon={{ name: "PlaybackCircleRewind", variant: "solid" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Go Back
                </Button>
              </ButtonGroup>
            }
          >
            {error && error !== undefined ? (
              // <Result status="error" title="Error" subTitle={JSON.stringify(errormsg)}  />
              <Banner
                className="bannertofront"
                rounded
                status="critical"
                title="Error"
              >
                {JSON.stringify(errormsg)}
              </Banner>
            ) : (
              ""
            )}
            {success !== "" ? (
              <Banner
                className="bannertofront"
                rounded
                status="success"
                title="Success"
              >
                {success}
              </Banner>
            ) : (
              //<Result status="success" title={success} />
              ""
            )}
            <Block
              inline
              className="form-example"
              loading={formikProps.isSubmitting}
            >
              <BlockSection size="full" className="hubtagfieldblocksection">
                <h4
                  className="hubtagfieldh4"
                  style={{ color: colors.fontColor }}
                >
                  Application Name<span className="hubtagfieldspan">*</span>
                </h4>
                <Field
                  as={Textbox}
                  className="zero-left zero-right hubtagfieldtext"
                  disabled={disableEdit()}
                  // label="Permission Name"
                  name="applicationname"
                  values={formikProps.values.applicationname}
                  placeholder="please enter application name"
                  pattern={formikProps.errors.applicationname}
                  validationMessage={formikProps.errors.applicationname}
                  required={true}
                  valid={true}
                  showValidIcon={true}
                />
              </BlockSection>
              <BlockSection size="full" className="hubtagfieldblocksection">
                <Field
                  as={Textbox}
                  className="zero-left zero-right hubtagfieldtext"
                  disabled={disableEdit()}
                  inputMode="text"
                  inputType="multiLine"
                  label="Application Description"
                  name="applicationdescription"
                  pattern={formikProps.errors.applicationdescription}
                  placeholder="please enter application description"
                  values={formikProps.values.applicationdescription}
                  validationMessage={formikProps.errors.applicationdescription}
                  required={true}
                  valid={true}
                  showValidIcon={true}
                />

                {typeof formikProps.errors.applicationdescription ===
                "string" ? (
                  <ErrorMessage
                    name="applicationdescription"
                    component="div"
                    className="error"
                  />
                ) : null}
              </BlockSection>
              <BlockSection size="full" className="hubtagfieldblocksection">
                <h4
                  className="hubtagfieldh4"
                  style={{ color: colors.fontColor }}
                >
                  Application URL<span className="hubtagfieldspan">*</span>
                </h4>
                <Field
                  as={Textbox}
                  className="zero-left zero-right hubtagfieldtext"
                  disabled={disableEdit()}
                  name="applicationurl"
                  values={formikProps.values.applicationurl}
                  pattern={formikProps.errors.applicationurl}
                  placeholder="please enter application url"
                  validationMessage={formikProps.errors.applicationurl}
                  required={true}
                  valid={true}
                  showValidIcon={true}
                />
              </BlockSection>
              <BlockSection size="full" className="hubtagfieldblocksection">
                <h4
                  className="hubtagfieldh4"
                  style={{ color: colors.fontColor }}
                >
                  Application Icon Path
                  <span className="hubtagfieldspan">*</span>
                </h4>
                <Field
                  as={Textbox}
                  className="zero-left zero-right hubtagfieldtext"
                  disabled={disableEdit()}
                  name="applicationicon"
                  values={formikProps.values.applicationicon}
                  pattern={formikProps.errors.applicationicon}
                  placeholder="please enter application icon"
                  validationMessage={formikProps.errors.applicationicon}
                  required={false}
                  valid={true}
                  showValidIcon={true}
                />
              </BlockSection>
              <BlockSection size="full">
                <Button
                  className="formsubmitbtn"
                  type="critical"
                  onClick={() => navigate("/application")}
                >
                  Cancel
                </Button>

                <Button
                  className="formsubmitbtn"
                  disabled={disableEdit()}
                  onClick={() => {
                    formikProps.isSubmitting = true;
                    formikProps.handleSubmit();
                  }}
                >
                  Submit
                </Button>
              </BlockSection>
              <BlockSection size="full">
                {permissions?.delete ? (
                  <Button
                    className="formsubmitbtn"
                    type="critical"
                    disabled={disableDelete()}
                    onClick={(e) => deleteApplications()}
                  >
                    Delete Application
                  </Button>
                ) : (
                  <span></span>
                )}
              </BlockSection>
            </Block>
          </Layout>
        )}
      </Formik>
    );
  }
};

export default WithAuth(WithAdminUIAuth(ApplicationDetail));
