import { React } from "react";
import { useState } from "react";
import { Layout } from "../../layout";
import { useNavigate } from "react-router-dom";
import Button, { ButtonGroup } from "@kaizen-ui/button";
import WithAuth from "../../components/auth/WithAuth";
import WithAdminUIAuth from "../../components/auth/WithAdminUIAuth";
import { Table, TableRow, TableHeader, TableDataCell } from "@kui-react/table";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Form from "react-bootstrap/Form";
import axios, { Axios } from "axios";
import { Message } from "@mui/icons-material";
import { Inline } from '@kui-react/inline'
import {Checkbox} from '@kui-react/checkbox'
import {  ProgressBarIndicator,ProgressBar, ProgressBarTrack} from '@kui-react/progress-bar'
import { ErrorMessage } from "formik";
import { requestOptionsForPOST } from "../../api/CommonServices";
import { handleApiError } from "../../api/utils";
import { saveAs } from "file-saver";
import toast from "react-hot-toast";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

  const failButtonStyle=
  {
    background:"red"
  };
const baseUrl = process.env.REACT_APP_APPHUB_API_ENDPOINT;
const BulkUserUpload = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openValidaion, setValidationModelOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [fileEntityType, setEntityType] = useState();
  const [sheetNoVisibility, setSheetNoVisibility] = useState("hidden");
  const [show, setShow] = useState(false);
  const [rowsData, setRowsData] = useState([,]);
  const [progressValue, setProgressValue]=useState(0);
  const [progressLabel, setProgressLabel] = useState('')
  const [progressMessage, setProgressMessage] = useState('')
  const [failedErrorMessage, setFailedErrorMessage] =useState('')

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleValidationOpen = () => {
    setValidationModelOpen(true);
  };
  const handleValidationClose = () => {
    setValidationModelOpen(false);
  };
  

  const handleErrorOpen=(message)=>{
    if(message!=null)
    {
      setErrorOpen(true);
      setFailedErrorMessage(message);
    }
  }
  const handleErrorClose=()=>{
    setErrorOpen(false);
    setFailedErrorMessage("");
  }

const handleExecutionProgress = async () =>{
var counter=0;
    while(counter<100)
    {
    var token = localStorage.getItem("ROCP_idToken");
    const headers = {
      Authorization: "Bearer " + token,
      "Accept": "*/*"
    };
    const selectionInput = [,];
    await axios.get(baseUrl+"UsersUpload/GetExecutionProgress",  {
      headers:headers,
      }).then((response)=>
          {  
            setProgressValue(response.data.progressValue); 
            setProgressMessage(response.data.message);

            if(response.data.isCompleted==true)
            {
              counter=100;
            }
          }) 
          .catch(error => {
              console.error('There was an error!', error);
          });
          counter++;
  }
  }
  const saveFile = (e) => {
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleUploading = (param) => {
    setProgressValue(0); 
    setProgressMessage('Loading');
    var message="";
  if(fileName==undefined || fileName==null)
  message = "Please select a file to upload.";
  if(file==undefined || file==null || file.size==0)
  message = "Please select a valid file to upload.";

  if(message!="")
  {
    alert("Please select valid file and action types.")
    return;
  }

    setShow(false);
    handleClose();
    handleExecutionProgress();
    const formData = new FormData();
    formData.append("formFile", file);
    formData.append("fileName", fileName);
    formData.append("DataKey", "mail");
    formData.append("EntityType", 1);


      try {
        var token = localStorage.getItem("ROCP_idToken");
        const headers = {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
          "Accept": "*/*"
        };
        const selectionInput = [,];
        axios.post(baseUrl+"UsersUpload", formData, {
          headers:headers,
          })
          .then((response) => {
            setRowsData([]);
            response.data.map(
              (item) =>
              { 
                selectionInput.push(item);
              });
              setRowsData(selectionInput);
              setFileName(null);
            handleClose();
          })
          .catch((error) => {
            console.error("There was an error!", error);
            alert("User upload process failed. Please check the log.");
            setProgressValue(0); 
            setProgressMessage('Start Uploading');
          });
      } catch (ex) {
        console.log(ex);
        alert("User upload process failed. Please check the log.");
        setProgressValue(0); 
        setProgressMessage('Start Uploading.');
      }
  };
  const getCurrentDateTime = () => {
    const currentDateTime = new Date();

    const year = currentDateTime.getFullYear();
    const month = (currentDateTime.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = currentDateTime.getDate().toString().padStart(2, "0");
    const hours = currentDateTime.getHours().toString().padStart(2, "0");
    const minutes = currentDateTime.getMinutes().toString().padStart(2, "0");
    const seconds = currentDateTime.getSeconds().toString().padStart(2, "0");

    const formattedDateTime = `${year}${month}${day}_${hours}${minutes}${seconds}`;

    return formattedDateTime;
  };

  const exportTemplate = async () => {
    try {
      var options = requestOptionsForPOST();
      await fetch(baseUrl + "usersupload/ExportTemplate", options).then((response) => 
      {
        if (response.ok) { return response;}
        throw new Error('Something went wrong');
      })
      .then(async (data) => 
      {
        if (data.status === 200 || data.statusCode === 200) 
        {
          const fileName = `UserUploadTemplate_${getCurrentDateTime()}.xlsx`;
          if (data.status !== "error") 
          {
              const blob =  await data.blob();
              saveAs(blob, fileName);
          } 
          else {
            toast.error("Unable to export data.");
          }
        }
      })
      .catch((error) => {
        console.log(error)
        handleApiError(error);
      });
      
    } catch (e) {
      return;
    }
  };


  return (
    <Layout
      key="laycustomresponse"
      title="User Migration"
      headerChildren={
        <ButtonGroup key="btngrpcreateresponse">
          <Button
            key="btncreateresponse"
            icon={{ name: "ActionsCircleAdd", variant: "solid" }}
             onClick={() => { exportTemplate(); }}
          >   Download Template
          </Button>
          <Button
            key="btncreateresponse1"
            icon={{ name: "ActionsCircleAdd", variant: "solid" }}
            onClick={() => {handleValidationOpen();}}
          >
            Read Validations
          </Button>
         
        </ButtonGroup>
        
      }
    >
      <div>
      
      <ProgressBar
        value={progressValue}
        max={100}
      />  
      <p>{progressMessage}</p>

        <div className="fileUploadDialogue">
          <Inline align="flex-start" gap="sm">
          <Button onClick={handleOpen}>Upload Users</Button> 
          </Inline>

          <Modal open={open} onClose={handleClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <Box sx={{ ...style, width: 400 }}>
              <Form>

              <Form.Group controlId="entityTypeSelection">
                </Form.Group>

                <br />
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control type="file" onChange={saveFile} />
                </Form.Group>
              </Form>

              <br/>
              <Button variant="primary" onClick={handleUploading}>
                Process Uploading
              </Button>
            </Box>
          </Modal>

          <Modal open={openValidaion} onClose={handleValidationClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <Box sx={{ ...style, width: 600 }}>
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <h4>Please read below validations are handled while doing bulk upload.</h4>
                  <ol>
                    <li> MailId column must be present in excel sheet.  </li>
                    <li> Make sure mailIds are in plane text rathar than a hyper link.</li>
                    <li> UId & MailId matching is not validated in AppHub. </li>
                    <li> Action column must contain only Update/ Insert OR Create value only. </li>
                    <li> Username will be inserted in new user creation only.</li>
                  </ol>
                </Form.Group>
              </Form>
             
            </Box>
          </Modal>
        </div>

  
        <div className="messageDiv">

          <Modal open={errorOpen} onClose={handleErrorClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <Box sx={{ ...style, width: 400 }}>
              <Form>

              <Form.Group controlId="entityTypeSelection">
                <h4><u>Error Message</u> </h4>
                <p>
                  {failedErrorMessage}
                </p>

                </Form.Group>
              </Form>
              <br/>
              <Button variant="primary" onClick={handleErrorClose}> Close </Button>
            </Box>
          </Modal>
        </div>
     
      
        <div className="container">
          <div className="table-responsive" id="usertable">
            <div className="row">
              <div className="col-sm-8">
                <Table>
                  <TableRow>
                   <TableHeader> Emailid </TableHeader>
                   <TableHeader> Status </TableHeader>
                    <TableHeader> Firstname </TableHeader>
                    <TableHeader> Lastname </TableHeader>
                    <TableHeader> Companyname </TableHeader>
                    <TableHeader> Countrycode </TableHeader>
                    <TableHeader> Earrestricted </TableHeader>
                    <TableHeader> Idmaccesscode </TableHeader>
                    <TableHeader> Licensetype </TableHeader>
                    <TableHeader> Productfamily </TableHeader>
                    <TableHeader> Idmaccesscode </TableHeader>
                    <TableHeader> Pec Id </TableHeader>
                    <TableHeader> Login Date </TableHeader>
                    <TableHeader> Is Active </TableHeader>
                    <TableHeader> Createddate </TableHeader>
                    <TableHeader> Modifiedby </TableHeader>
                    <TableHeader> Modifieddate </TableHeader>
                    
                  </TableRow>
                 
                    {rowsData?.map((columns) => {
                      return (
                        <TableRow>

                          <TableDataCell>{columns.emailid}</TableDataCell>
                          
                          <TableDataCell> <Button onClick={() => handleErrorOpen(columns.message)} 
                          type={columns.message?"critical":"primary"}
                          > 
                          {columns.statusMessage}</Button>  </TableDataCell>
                          {/* <TableDataCell> <Button onClick={handleErrorOpen(columns.message)} className="btn-primary"> {columns.statusMessage}</Button>  </TableDataCell> */}
                          <TableDataCell>{columns.firstname}</TableDataCell>
                          <TableDataCell>{columns.lastname}</TableDataCell>
                          <TableDataCell>{columns.companyname}</TableDataCell>
                          <TableDataCell>{columns.countrycode}</TableDataCell>
                          <TableDataCell>{columns.earrestricted=true?"True":"False" }</TableDataCell>
                          <TableDataCell>{columns.idmaccesscode}</TableDataCell>
                          <TableDataCell>{columns.licensetype}</TableDataCell>
                          <TableDataCell>{columns.productfamily}</TableDataCell>
                          <TableDataCell>{columns.idmaccesscode}</TableDataCell>
                          <TableDataCell>{columns.pecid}</TableDataCell>
                          <TableDataCell>{columns.logindate}</TableDataCell>
                          <TableDataCell>{columns.isactive =true?"False":"True"}</TableDataCell>
                          <TableDataCell>{columns.createddate}</TableDataCell>
                          <TableDataCell>{columns.modifiedby}</TableDataCell>
                          <TableDataCell>{columns.modifieddate}</TableDataCell>
                          
                         
                          </TableRow>
                      );
                    })}
                  
                </Table>
              </div>


              <div className="col-sm-4"></div>
            </div>
          </div>
        </div>
      
      </div>
    </Layout>
  );
};

export default WithAuth(WithAdminUIAuth(BulkUserUpload));
