// const baseUrl = process.env.REACT_APP_APPHUB_API_ENDPOINT;

const baseUrl = process.env.REACT_APP_starfleetEndpoint;

// const baseUrl = process.env.starfleetEndpoint;

function requestDELETEOptions() {
  const loggedinuser = localStorage.getItem("email");

  if (loggedinuser === undefined || loggedinuser === null) return;
  const storedToken = JSON.parse(localStorage.getItem("token"));
  return {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      authorization: "Bearer " + storedToken.access_token,
    },
  };
}

export const revokeAllTokenFromStarfleet = async () => {
  var options = requestDELETEOptions();
  //?level=client&client=" + clientId
  const response = fetch(baseUrl + "assets/v2/Tokens", options).then(
    async (res) => {
      // if(res)
      // {
      // if(res.ok) {return }
      // }
      // const data = await res.json();
      // if (!res.ok) {
      // }
      return res;
    }
  );
  return response;
};
