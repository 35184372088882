import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import UserContext from "./components/auth/UserContext";
import UserPermissionContext from "./components/auth/UserPermissionContext";

import { ThemeProvider } from "@kui-react/theme";
import { GetUserAccess } from "./api";
import routes from "./routes/index";
import "./styles/app.css";

function App() {
    const [userDetails, setUserData] = useState([]);

    const [userPermissions, setUserPermission] = useState([]);
    const [status, setStatus] = useState("loading");

    useEffect(() => {
        (async () => {
            var searchParams = new URLSearchParams(window.location.search);

            if (searchParams.get("RelayState")) {
                var code = decodeURIComponent(searchParams.get("RelayState"));
                const IsUserLoggedIn = localStorage.getItem("IsUserLoggedIn");
                if (IsUserLoggedIn === "true" || IsUserLoggedIn === "inprogress") {
                    setStatus("loading");
                    localStorage.setItem("deeplink", code);
                    window.location.assign(code);
                } else {
                    setStatus("done");
                    //console.log(code);
                    localStorage.setItem("deeplink", code);
                }
            }

            const IsUserLoggedIn = localStorage.getItem("IsUserLoggedIn");
            if (IsUserLoggedIn === "true" || IsUserLoggedIn === "inprogress") {
                userDetails.splice();
                userDetails.push(localStorage.getItem("email"));
                userDetails.push(localStorage.getItem("name"));
                setUserData(userDetails);

                const token = localStorage.getItem("ROCP_idToken");
                if (token !== undefined && token !== null && token !== "") {
                    let response = await GetUserAccess();

                    if (response.status === "serverError") {
                        setStatus("internalServerError");
                        localStorage.clear();
                        sessionStorage.clear();
                    } else if (response.status === "error") {
                        // toast.error("Server Error");
                        setStatus("error");
                    } else if (response.status === "success") {
                        setUserPermission(response.data);
                        setStatus("done");
                    } else {
                        if (response.status === "unauthorized") {
                            localStorage.clear();
                            sessionStorage.clear();
                            setStatus("unauthorized");
                        }
                        if (response.status === "internalServerError") {
                            setStatus("internalServerError");
                            localStorage.clear();
                            sessionStorage.clear();
                        }
                    }
                } else {
                    setStatus("done");
                }
            }
            setStatus("done");
        })();
    }, []);

    // if (status === "unauthorized") {
    //   return <Login />;
    // }

    // if (status === "error") {
    //   return <ServerError />;
    // }

    return (
        <>
            {status === "loading" ? (
                <div className="loader-container">
                    {" "}
                    <div className="loader"></div>
                </div>
            ) : (
                <UserContext.Provider value={userDetails}>
                    <UserPermissionContext.Provider value={userPermissions}>
                        <ThemeProvider theme="light" withCanvas withFonts withReset>
                            <Router>
                                <Routes>
                                    {routes.map((route, index) => (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            element={route.component}
                                        />
                                    ))}
                                </Routes>
                            </Router>
                            <Toaster />
                        </ThemeProvider>
                    </UserPermissionContext.Provider>
                </UserContext.Provider>
            )}
        </>
    );
}

export default App;
