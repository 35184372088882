import React from "react";
import { Link } from "react-router-dom";

import Header from "../../components/header";

import "./styles.css";
import "../../styles/Loader.css";

const UserError = () => {
  return (
    <div>
      <div>
        <Header
          headerHeading="NVIDIA Application Hub"
          loginHeader={true}
          dashboardHeader={false}
        ></Header>
        <div className="centered-message-app">
          <div className="centered-message">
            <p>
              {" "}
              Not a valid user. Please visit{" "}
              <Link className="sign-in-text sign-in-text-medium" to="/needhelp">
                Need help
              </Link>{" "}
              for more information.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserError;
