const crossOriginPageUrl = process.env.REACT_APP_nvidiaauthhints;
//const crossOriginPageUrl = process.env.nvidiaauthhints;

export function readHint() {
  return new Promise((resolve) => {
    const { origin: targetOrigin } = new URL(crossOriginPageUrl);
    const iframe = document.createElement("iframe");
    iframe.hidden = true;
    iframe.src = crossOriginPageUrl;
    function responseHandler(event) {
      if (event.origin === targetOrigin) {
        iframe.parentNode.removeChild(iframe);
        return resolve(event.data);
      }
    }
    // Listener to capture the response from the iframe
    window.addEventListener("message", responseHandler, { once: true });

    iframe.onload = () => {
      iframe.contentWindow.postMessage({ type: "read" }, targetOrigin);
    };
    // Appending the iframe triggers the request
    document.body.appendChild(iframe);
  });
}

export function writeUser(login_hint, idp_id, timestamp, sub) {
  const { origin: targetOrigin } = new URL(crossOriginPageUrl);
  const iframeDOC = document.getElementById("NGC_Iframe");
  if (iframeDOC) {
    document.body.removeChild(iframeDOC);
  }
  const iframe = document.createElement("iframe");
  iframe.hidden = true;
  iframe.src = crossOriginPageUrl;
  iframe.id = "NGC_Iframe";
  var func = function () {
    const message = { type: "write", login_hint, idp_id, timestamp, sub };
    iframe.contentWindow.postMessage(message, targetOrigin);
    console.log("loaded!");
  };
  if (iframe.addEventListener) iframe.addEventListener("load", func, true);
  else if (iframe.attachEvent) iframe.attachEvent("onload", func);

  document.body.appendChild(iframe);
}

export function deleteUser() {
  const { origin: targetOrigin } = new URL(crossOriginPageUrl);
  const iframe = document.createElement("iframe");
  iframe.hidden = true;
  iframe.src = crossOriginPageUrl;
  iframe.onload = () => {
    iframe.contentWindow.postMessage({ type: "delete" }, targetOrigin);
  };
  document.body.appendChild(iframe);
}
