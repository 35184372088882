import React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { Table, TableRow, TableHeader, TableDataCell } from "@kui-react/table";
import { Checkbox } from "@kui-react/checkbox";

function PermissionTable({ items, handleCheckboxChange }) {
  //const [options, setoptions] = useState([]);

  useEffect(() => {
    // componentDidMount and componentDidUpdate logic here
    // setoptions(selectedPermissions);
  }, []);

  return (
    <Table>
      <TableRow>
        <TableHeader>Permission</TableHeader>
        <TableHeader>Read</TableHeader>
        <TableHeader>Create</TableHeader>
        <TableHeader>Update</TableHeader>
        <TableHeader>Delete</TableHeader>
      </TableRow>
      {items.map((item, index) => {
        return (
          <TableRow>
            <TableDataCell>{item.name}</TableDataCell>

            {item.read === null ? (
              <TableDataCell></TableDataCell>
            ) : (
              <TableDataCell>
                <div className="input-group">
                  <div className="checkbox-inline">
                    <Checkbox
                      className="accessType"
                      value={item.read}
                      checked={item.readChecked}
                      id={
                        item.permissiontype +
                        "-" +
                        item.name +
                        "read" +
                        "#" +
                        item.read
                      }
                      onChange={handleCheckboxChange(index)}
                      name={item.permissiontype + "#" + item.name}
                    />
                  </div>
                </div>
              </TableDataCell>
            )}

            {item.create === null ? (
              <TableDataCell></TableDataCell>
            ) : (
              <TableDataCell>
                <div className="input-group">
                  <div className="checkbox-inline">
                    <Checkbox
                      value={item.create}
                      checked={item.createChecked}
                      id={
                        item.permissiontype +
                        "-" +
                        item.name +
                        "create" +
                        "#" +
                        item.create
                      }
                      onChange={handleCheckboxChange(index)}
                      name={item.permissiontype + "#" + item.name}
                    />
                  </div>
                </div>
              </TableDataCell>
            )}

            {item.update === null ? (
              <TableDataCell></TableDataCell>
            ) : (
              <TableDataCell>
                <div className="input-group">
                  <div className="checkbox-inline">
                    <Checkbox
                      value={item.update}
                      checked={item.updateChecked}
                      id={
                        item.permissiontype +
                        "-" +
                        item.name +
                        "-update" +
                        "#" +
                        item.update
                      }
                      onChange={handleCheckboxChange(index)}
                      name={item.permissiontype + "#" + item.name}
                    />
                  </div>
                </div>
              </TableDataCell>
            )}

            {item.delete === null ? (
              <TableDataCell></TableDataCell>
            ) : (
              <TableDataCell>
                <div className="input-group">
                  <div className="checkbox-inline">
                    <Checkbox
                      value={item.delete}
                      checked={item.deleteChecked}
                      id={
                        item.permissiontype +
                        "-" +
                        item.name +
                        "-delete" +
                        "#" +
                        item.delete
                      }
                      onChange={handleCheckboxChange(index)}
                      name={item.permissiontype + "#" + item.name}
                    />
                  </div>
                </div>
              </TableDataCell>
            )}
          </TableRow>
        );
      })}
    </Table>
  );
}

PermissionTable.defaultProps = {
  items: [],
  // acceptedPermissions: [],
  // selectedPermissions: [],
};

PermissionTable.propTypes = {
  items: PropTypes.array,
  // acceptedPermissions: PropTypes.array,
  // selectedPermissions: PropTypes.array,
};

export default React.memo(PermissionTable);
