/**
 * This is an example Listing page that lives on the route '/list-example'
 */
import React, { useContext, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import KaizenTable from "@kaizen-ui/table";
import { fetchAuditLogList } from "../../../api";
import { Layout } from "../../../layout";
import AccessibilityContext from "../../../components/auth/UserPermissionContext";
import "./styles.css";
import WithAuth from "../../../components/auth/WithAuth";
import { useParams } from "react-router-dom";
import Button from "@kaizen-ui/button";
import ButtonGroup from "@kaizen-ui/button";
// Kaizen table has small typing bug at the moment. This ensures columns and
// data props are registered correctly
const Table = KaizenTable;

const UserAuditLog = () => {
  let { email } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [pageIndex, setPage] = useState(1);
  const [pageSize, setSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [sorting, setSorting] = useState([{ id: "auditid", desc: true }]);
  const [filtering, setFiltering] = useState([{ id: "emailid", value: email }]);
  const [totalResults, setTotalResults] = useState(0);

  const permissions = useContext(AccessibilityContext).filter(
    (x) => x.pagename === "auditlog"
  )[0];

  const hiddenColumns = ["auditid", "username", "recordtype"];

  const columns = [
    { accessor: "auditid" },
    // { accessor: "audittype", Header: "Audittype" },
    // { accessor: "recordtype", Header: "Table Name" },
    { accessor: "auditdatestring", Header: "Auditdate" },
    { accessor: "comment", Header: "Comment" },
    { accessor: "emailid", Header: "Email" },
    // { accessor: "oldvalue", Header: "Oldvalue" },
    // { accessor: "newvalue", Header: "New Value" },
    { accessor: "username", Header: "User Name" },
  ];
  const IsArrayEqual = (first, second) => {
    return JSON.stringify(second) === JSON.stringify(first);
  };

  const rowColorChange = (row) => {
    const auditid = row.auditid;
    if (auditid % 2 === 0) {
      return false;
    } else return true;
  };

  const handleFetchData = useCallback(
    (params) => {
      const insorting = JSON.stringify(params.sortBy);
      const inSortby = JSON.parse(insorting);
      const infiltering = JSON.stringify(params.filters);
      const inFilterBy = JSON.parse(infiltering);
      //console.log('currentPage : '+currentPage)
      if (IsArrayEqual(inSortby, sorting) === false) {
        //console.log('SortBY is not equal')
        setSorting(inSortby);
      }
      if (IsArrayEqual(inFilterBy, filtering) === false) {
        //console.log('Filter is not equal')
        setFiltering(inFilterBy);
      }
      if (
        pageIndex !== params.pageIndex + 1 &&
        currentPage !== params.pageIndex + 1
      ) {
        //console.log('before pageIndex :' + pageIndex)
        setPage(params.pageIndex + 1);
        //console.log('after pageIndex :' + pageIndex)
      }
      if (pageSize !== params.pageSize) {
        setSize(params.pageSize);
      }
    },
    [currentPage, pageIndex, pageSize, sorting, filtering]
  );
  useEffect(() => {
    if (permissions?.read === false) {
      navigate("/unauthorized");
    }
    if (pageIndex > 0) {
      if (
        filtering !== undefined &&
        filtering !== null &&
        filtering.length &&
        filtering.length > 0
      ) {
        //setFiltering([{ id: "email", value: email }]);
        fetchAuditLogList(pageIndex, pageSize, sorting, filtering).then(
          (data) => {
            setData(data.contents);
            setCurrentPage(pageIndex);
            setTotalPages(Math.ceil(data.resultCount / pageSize));
            setTotalResults(data.resultCount);
          }
        );
      }
    }
  }, [
    pageIndex,
    pageSize,
    sorting,
    filtering,
    permissions?.read,
    navigate,
    //  email,
  ]);

  if (permissions?.read === false) {
    navigate("/unauthorized");
  }

  if (permissions?.read) {
    return (
      <Layout
        title="User Audit Log"
        headerChildren={
          <ButtonGroup key="btngrpuser">
            <Button
              icon={{ name: "PlaybackCircleRewind", variant: "solid" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </Button>
          </ButtonGroup>
        }
      >
        <Table
          key="tblAuditLogs"
          initialState={{
            pageSize: pageSize,
            pageIndex: pageIndex,
            hiddenColumns: hiddenColumns,
          }}
          paginationPageSizeOptions={[
            { label: "5", value: 5 },
            { label: "10", value: 10 },
            { label: "25", value: 25 },
            { label: "50", value: 50 },
            { label: "100", value: 100 },
          ]}
          rowOnClick={({ values }) => navigate(`/auditlog/${values.auditid}`)}
          // @ts-ignore
          columns={columns}
          data={data}
          pageCount={totalPages}
          totalResults={totalResults}
          fetchData={handleFetchData}
          // fetching={true}
          autoResetPage={false}
          autoResetSortBy={false}
          disableColumnHiding={true}
          disableExport={false}
          disableFilters={true}
          disableSortBy={true}
          getRowProps={(row) => ({
            style: {
              background: rowColorChange(row) ? "" : "grey",
            },
          })}
        />
      </Layout>
    );
  }
};

export default WithAuth(UserAuditLog);
