import React from "react";
import "./GlobalFooter.css";

function GlobalFooter() {
  return (
    <div className="global-footer-global-footer">
      <div className="global-footer-container">
        <div className="global-footer-branding">
          <div className="global-footer-logo">
            <div className="global-footer-logo2">
              <svg
                className="global-footer-eye-mark"
                width="45"
                height="31"
                viewBox="0 0 45 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.8077 9.20512V6.50012C17.0683 6.48179 17.3306 6.46679 17.5979 6.45845C24.922 6.22512 29.7273 12.8168 29.7273 12.8168C29.7273 12.8168 24.5377 20.1001 18.9736 20.1001C18.1719 20.1001 17.4543 19.9701 16.8077 19.7501V11.5451C19.6598 11.8935 20.2323 13.1651 21.9462 16.0518L25.7584 12.8035C25.7584 12.8035 22.9755 9.11512 18.2841 9.11512C17.7744 9.11512 17.2861 9.15179 16.8061 9.20345L16.8077 9.20512ZM16.8061 0.266788V4.30846C17.0683 4.28679 17.3323 4.27012 17.5962 4.26012C27.7808 3.91346 34.4171 12.7001 34.4171 12.7001C34.4171 12.7001 26.796 22.0635 18.8548 22.0635C18.1274 22.0635 17.4461 21.9951 16.8061 21.8818V24.3801C17.3537 24.4501 17.9212 24.4918 18.5117 24.4918C25.9002 24.4918 31.2449 20.6801 36.4197 16.1668C37.2775 16.8601 40.7894 18.5485 41.512 19.2885C36.5912 23.4501 25.1266 26.8035 18.6256 26.8035C17.9987 26.8035 17.3966 26.7651 16.8061 26.7085V30.2185H44.8903V0.268456H16.8077L16.8061 0.266788ZM16.8061 19.7485V21.8818C9.9718 20.6501 8.07477 13.4735 8.07477 13.4735C8.07477 13.4735 11.3558 9.80012 16.8061 9.20512V11.5451C16.8061 11.5451 16.7995 11.5451 16.7962 11.5451C13.9358 11.1985 11.7022 13.8985 11.7022 13.8985C11.7022 13.8985 12.9543 18.4435 16.8077 19.7518L16.8061 19.7485ZM4.67002 13.1651C4.67002 13.1651 8.71976 7.12679 16.8077 6.50179V4.31179C7.85043 5.03846 0.0924072 12.7035 0.0924072 12.7035C0.0924072 12.7035 4.48527 25.5368 16.8077 26.7118V24.3835C7.76465 23.2335 4.67002 13.1651 4.67002 13.1651Z"
                  fill="#76B900"
                />
              </svg>
              <svg
                className="global-footer-wordmark"
                width="109"
                height="22"
                viewBox="0 0 109 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M106.801 19.2901V18.8418H107.086C107.242 18.8418 107.454 18.8534 107.454 19.0451C107.454 19.2534 107.345 19.2901 107.161 19.2901H106.801ZM106.801 19.6051H106.992L107.435 20.3901H107.92L107.431 19.5735C107.684 19.5551 107.891 19.4335 107.891 19.0885C107.891 18.6618 107.6 18.5234 107.106 18.5234H106.392V20.3884H106.803V19.6035L106.801 19.6051ZM108.88 19.4568C108.88 18.3618 108.037 17.7251 107.098 17.7251C106.159 17.7251 105.312 18.3601 105.312 19.4568C105.312 20.5535 106.153 21.1901 107.098 21.1901C108.043 21.1901 108.88 20.5518 108.88 19.4568ZM108.365 19.4568C108.365 20.2551 107.784 20.7901 107.098 20.7901V20.7851C106.392 20.7901 105.823 20.2551 105.823 19.4568C105.823 18.6585 106.394 18.1251 107.098 18.1251C107.802 18.1251 108.365 18.6585 108.365 19.4568Z"
                  fill="black"
                />
                <path
                  d="M43.4782 0.928451V20.5885H48.9747V0.928451H43.4782ZM0.249084 0.901784V20.5885H5.79336V5.30679L10.1186 5.32178C11.5405 5.32178 12.5253 5.66678 13.2099 6.40512C14.0792 7.34178 14.4339 8.85012 14.4339 11.6101V20.5885H19.805V9.71178C19.805 1.94845 14.9073 0.901784 10.1169 0.901784H0.249084ZM52.3266 0.928451V20.5868H61.2394C65.9885 20.5868 67.5375 19.7885 69.2151 18.0001C70.3996 16.7435 71.165 13.9868 71.165 10.9735C71.165 8.21012 70.5167 5.74512 69.3867 4.21012C67.3511 1.46512 64.4181 0.928451 60.0385 0.928451H52.3266ZM57.7769 5.20845H60.1391C63.567 5.20845 65.784 6.76345 65.784 10.7985C65.784 14.8335 63.567 16.3901 60.1391 16.3901H57.7769V5.20845ZM35.5553 0.928451L30.9694 16.5085L26.5749 0.928451H20.643L26.9197 20.5868H34.8393L41.1655 0.928451H35.5569H35.5553ZM73.7218 20.5868H79.2183V0.928451H73.7202V20.5868H73.7218ZM89.1274 0.935117L81.4535 20.5801H86.8724L88.0865 17.1085H97.1675L98.3172 20.5801H104.2L96.468 0.933451H89.1274V0.935117ZM92.6938 4.52012L96.0226 13.7235H89.2593L92.6938 4.52012Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          <div className="global-footer-country">United States </div>
        </div>
        <div className="global-footer-container2">
          <div className="global-footer-links">
            <div className="global-footer-item">
              <a
                className="global-footer-privacy-policy"
                href="https://www.nvidia.com/en-us/about-nvidia/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy{" "}
              </a>
              <span className="equal-space">|</span>
              {/* <a
                className="global-footer-privacy-policy"
                href="https://www.nvidia.com/en-us/about-nvidia/legal-info/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Legal{" "}
              </a>
              <span className="equal-space">|</span> */}
              <a
                className="global-footer-manage-my-privacy"
                href="https://www.nvidia.com/en-us/privacy-center/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Manage My Privacy
              </a>
              <span className="equal-space">|</span>
              <a
                className="global-footer-manage-my-privacy"
                href="https://www.nvidia.com/en-us/preferences/email-preferences/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Do Not Sell or Share My Data
              </a>
              <span className="equal-space">|</span>
              <a
                className="global-footer-manage-my-privacy"
                href="https://www.nvidia.com/en-us/about-nvidia/terms-of-service/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
              <span className="equal-space">|</span>
              <a
                className="global-footer-legal"
                href="https://www.nvidia.com/en-us/about-nvidia/accessibility/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Accessibility
              </a>
              <span className="equal-space">|</span>
              <a
                className="global-footer-accessibility"
                href=" https://www.nvidia.com/en-us/about-nvidia/company-policies/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Corporate Policies
              </a>
              <span className="equal-space">|</span>
              <a
                className="global-footer-product-security"
                href="https://www.nvidia.com/en-us/product-security/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Product Security
              </a>
              <span className="equal-space">|</span>
              <a
                className="global-footer-contact"
                href="https://www.nvidia.com/en-us/contact/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact
              </a>
            </div>
          </div>
          <div className="copyright-2022-nvidia-corporation">
            Copyright © {new Date().getFullYear()} NVIDIA Corporation
          </div>
        </div>
      </div>
    </div>
  );
}

export default GlobalFooter;
