import { useContext, useEffect, useState } from "react";
import Spinner from "@kaizen-ui/spinner";
import { fetchAuditLog } from "../../../api";
import { Layout } from "../../../layout";
import { useParams } from "react-router-dom";
import { ErrorMessage, Field, Formik } from "formik";
import Block from "@kaizen-ui/block";
import Button from "@kaizen-ui/button";
import Textbox from "@kaizen-ui/textbox";
import "./styles.css";
import { BlockSection } from "@kaizen-ui/block";
import ButtonGroup from "@kaizen-ui/button";
import "react-datepicker/dist/react-datepicker.css";

import { useNavigate } from "react-router-dom";
import WithAuth from "../../../components/auth/WithAuth";
import "./styles.css";
import { KaizenThemeContext } from "@kaizen-ui/complete";
import AccessibilityContext from "../../../components/auth/UserPermissionContext";
import WithAdminUIAuth from "../../../components/auth/WithAdminUIAuth";
const Loading = () => (
  <Layout title="Loading...">
    <Spinner className="loading-spinner" size="small" />
  </Layout>
);

const AuditLogDetail = () => {
  let { id } = useParams();
  const [auditdata, setData] = useState();
  const { colors } = useContext(KaizenThemeContext);

  useEffect(() => {
    fetchAuditLog(Number(id)).then((data) => {
      setData(data);
    });
  }, [id]);

  const navigate = useNavigate();
  const permissions = useContext(AccessibilityContext).filter(
    (x) => x.pagename === "auditlog"
  )[0];
  if (!auditdata) return <Loading />;
  if (permissions != null && permissions !== undefined && !permissions.read) {
    navigate("/unauthorized");
  }

  if (permissions?.read) {
    return (
      <Formik
        initialValues={auditdata}
        initialTouched={{
          auditid: true,
          recordid: true,
          recordtype: true,
          Keyword: true,
          oldvalue: true,
          newvalue: true,
          username: true,
          auditdatestring: true,
          comment: true,
        }}
        onReset={(values) => {
          values = auditdata;
        }}
        validateOnMount
      >
        {(formikProps) => (
          <Layout
            title="Audit Log"
            headerChildren={
              <ButtonGroup>
                <Button
                  icon={{ name: "PlaybackCircleRewind", variant: "solid" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Go Back
                </Button>
              </ButtonGroup>
            }
          >
            <Block
              inline
              className="form-example"
              loading={formikProps.isSubmitting}
            >
              <BlockSection size="full" className="hubtagfieldblocksection">
                <h4
                  className="hubtagfieldh4"
                  style={{ color: colors.fontColor }}
                >
                  Email
                </h4>
                <Field
                  as={Textbox}
                  className="zero-left zero-right hubtagfieldtext"
                  name="emailid"
                  values={formikProps.values.emailid}
                  disabled={true}
                />
              </BlockSection>
              <BlockSection size="full" className="hubtagfieldblocksection">
                <h4
                  className="hubtagfieldh4"
                  style={{ color: colors.fontColor }}
                >
                  Record Id
                </h4>
                <Field
                  as={Textbox}
                  className="zero-left zero-right hubtagfieldtext"
                  name="recordid"
                  values={formikProps.values.recordid}
                  disabled={true}
                />
              </BlockSection>
              <BlockSection size="full" className="hubtagfieldblocksection">
                <h4
                  className="hubtagfieldh4"
                  style={{ color: colors.fontColor }}
                >
                  Table Name
                </h4>
                <Field
                  as={Textbox}
                  className="zero-left zero-right hubtagfieldtext"
                  name="recordtype"
                  values={formikProps.values.recordtype}
                  disabled={true}
                />
              </BlockSection>
              <BlockSection size="full" className="hubtagfieldblocksection">
                <h4
                  className="hubtagfieldh4"
                  style={{ color: colors.fontColor }}
                >
                  Audit Type
                </h4>
                <Field
                  as={Textbox}
                  className="zero-left zero-right hubtagfieldtext"
                  name="audittype"
                  values={formikProps.values.audittype}
                  disabled={true}
                />
              </BlockSection>
              <BlockSection size="full" className="hubtagfieldblocksection">
                <h4
                  className="hubtagfieldh4"
                  style={{ color: colors.fontColor }}
                >
                  Keyword
                </h4>
                <Field
                  as={Textbox}
                  className="zero-left zero-right hubtagfieldtext"
                  name="keyword"
                  inputType="multiLine"
                  values={formikProps.values.keyword}
                  disabled={true}
                />
              </BlockSection>
              <BlockSection size="full" className="hubtagfieldblocksection">
                <h4
                  className="hubtagfieldh4"
                  style={{ color: colors.fontColor }}
                >
                  Oldvalue
                </h4>
                <Field
                  as={Textbox}
                  className="zero-left zero-right hubtagfieldtext"
                  name="oldvalue"
                  inputType="multiLine"
                  values={formikProps.values.oldvalue}
                  disabled={true}
                />
              </BlockSection>
              <BlockSection size="full" className="hubtagfieldblocksection">
                <h4
                  className="hubtagfieldh4"
                  style={{ color: colors.fontColor }}
                >
                  Newvalue
                </h4>
                <Field
                  as={Textbox}
                  className="zero-left zero-right hubtagfieldtext"
                  name="newvalue"
                  inputType="multiLine"
                  values={formikProps.values.newvalue}
                  disabled={true}
                />
              </BlockSection>
              <BlockSection size="full" className="hubtagfieldblocksection">
                <h4
                  className="hubtagfieldh4"
                  style={{ color: colors.fontColor }}
                >
                  User Full Name
                </h4>
                <Field
                  as={Textbox}
                  className="zero-left zero-right hubtagfieldtext"
                  name="username"
                  values={formikProps.values.username}
                  disabled={true}
                />
              </BlockSection>
              <BlockSection size="full" className="hubtagfieldblocksection">
                <h4
                  className="hubtagfieldh4"
                  style={{ color: colors.fontColor }}
                >
                  Audit Date
                </h4>
                <Field
                  as={Textbox}
                  className="zero-left zero-right hubtagfieldtext"
                  name="auditdate"
                  values={formikProps.values.auditdatestring}
                  disabled={true}
                />
              </BlockSection>
              <BlockSection size="full" className="hubtagfieldblocksection">
                <h4
                  className="hubtagfieldh4"
                  style={{ color: colors.fontColor }}
                >
                  Comment
                </h4>
                <Field
                  as={Textbox}
                  className="zero-left zero-right hubtagfieldtext"
                  name="comment"
                  values={formikProps.values.comment}
                  disabled={true}
                />
              </BlockSection>
              <BlockSection size="full">
                <Button
                  className="formsubmitbtn"
                  type="critical"
                  onClick={() => navigate("/auditlog")}
                >
                  Cancel
                </Button>
              </BlockSection>
            </Block>
          </Layout>
        )}
      </Formik>
    );
  }
};

export default WithAuth(WithAdminUIAuth(AuditLogDetail));
