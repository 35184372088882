import "./styles.css";
import React, { useEffect } from "react";
export default function AuthError() {
  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
  }, []);
  return (
    <div className="unauthorized-page-container">
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            dispplay: "flex",
            justifyContent: "space-around",
            flexDirection: "row",
            marginBottom: "20px",
          }}
        >
          <p style={{ fontSize: "40px", margin: "0px", fontWeight: "bold" }}>
            <span style={{ fontSize: "40px", color: "black" }}>
              {" "}
              Authentication error
            </span>
          </p>
        </div>
        <div
          style={{
            dispplay: "flex",
            justifyContent: "space-around",
            flexDirection: "row",
            marginBottom: "20px",
          }}
        >
          <br></br> <br></br>
          <p>There was an error while trying to authenticate your request.</p>
        </div>
      </div>
    </div>
  );
}
