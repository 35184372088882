/**
 * This component is used on all pages to add menus and layout css.
 */
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { KaizenThemeContext } from "@kaizen-ui/styles";
import AppBar from "@kaizen-ui/app-bar";
import Menu, { MenuContent, MenuItem, MenuFooter } from "@kaizen-ui/menu";
import ContentHeader from "@kaizen-ui/content-header";
import { Label } from "reactstrap";
import Button from "@kaizen-ui/button";
import "./styles.css";
import { GetLoggedinUser } from "../common/utils";
import { useNavigate } from "react-router-dom";

export const Layout = ({ children, headerChildren, title }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // if (!auth.isLoggedIn) {
    //   navigate("/login");
    // }
  }, []);
  const { colors, spacing } = useContext(KaizenThemeContext);

  return (
    <div className="layout" style={{ backgroundColor: colors.pageBackground }}>
      <AppBar
        className="BrandNavigationheader-logo"
        // app="App Hub"
        appBarActions={<></>}
      />

      <div
        className="BrandNavigationheader"
        style={{
          width: "100%",
          // backgroundColor: "black",
          background: "var(--grayscale-black, #000)",
          flexDirection: "row",
          flexWrap: "wrap",
          textAlign: "center",
          justifyContent: "space-around",
        }}
      >
        <Label style={{ color: "#fff" }}>Welcome {GetLoggedinUser()}</Label>
        <span className="middlespace"></span>
        <button
          class="dashboard-header-primary dashboard-header-frame-1"
          type="submit"
          onClick={() => {
            navigate("/logout");
          }}
        >
          Log Out
        </button>
      </div>
      {/* <Header /> */}
      <div>
        <Menu
          itemRenderer={({ title, href }) => <Link to={href}>{title}</Link>}
          className="hubmenu"
        >
          <MenuContent>
            <MenuItem href="/role" icon={{ name: "ViewList" }} title="Role" />
            <MenuItem
              href="/permission"
              icon={{ name: "ViewList" }}
              title="Permission"
            />
            <MenuItem
              href="/application"
              icon={{ name: "ViewList" }}
              title="Application"
            />
            <MenuItem href="/user" icon={{ name: "ViewList" }} title="user" />
            <MenuItem
              href="/auditlog"
              icon={{ name: "ViewList" }}
              title="AuditLog"
            />
            <MenuItem
              href="/bulkuserupload"
              icon={{ name: "ViewList" }}
              title="Bulk User Upload"
            />
            <MenuItem
              href="/bulkroleupdate"
              icon={{ name: "ViewList" }}
              title="Bulk Role Update"
            />
            <MenuItem
              href="/bulkuploadmapping"
              icon={{ name: "ViewList" }}
              title="Upload Mapping"
            />
          </MenuContent>

          <MenuFooter>App Hub Version: 1.0</MenuFooter>
        </Menu>
      </div>
      <div className="page-content">
        <ContentHeader className="content-header" title={title}>
          {headerChildren}
        </ContentHeader>
        <div className="content-wrapper" style={{ padding: spacing.four }}>
          {children}
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  );
};
