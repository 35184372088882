import { useNavigate } from "react-router-dom";

export const ExtractErrorDetails = (res, data) => {
  var errordetails = [];
  if (data) {
    if (data.detail) {
      if (typeof data.detail === "string") {
        errordetails.push("Message:" + data.detail);
      } else {
        errordetails = data.detail.map((detail) => {
          return detail;
        });
      }
    } else {
      if (data.length > 0) {
        errordetails = data.map((detail) => {
          return detail;
        });
      } else {
        if (data.error !== undefined && data.error !== "") {
          errordetails.push("Message:" + data.error);
        } else {
          errordetails.push("Message:" + data);
        }
      }
    }
  }
  const error = new ErrorStatus(res.status, res.statusText, errordetails);
  return error;
};

export function HandleError(res) {
  const navigate = useNavigate();
  if (res.status === 401) {
    navigate("/login");
  }
}

export class ErrorStatus {
  constructor(status_code, status_text, status_message) {
    this.status_code = status_code;
    this.status_text = status_text;
    this.status_message = status_message;
    this.status_code = status_code;
    this.status_text = status_text;
    this.status_message = status_message;
  }
}
export class ErrorDetail {
  constructor(location, message, type) {
    this.location = location;
    this.message = message;
    this.type = type;
    this.location = location;
    this.message = message;
    this.type = type;
  }
}
