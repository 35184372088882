import { ExtractErrorDetails } from "./Errorservice";
import {
  ExtractSortBy,
  ExtractFilterBy,
  requestOptionsForGET,
  requestOptionsForPATCH,
  requestOptionsForDELETE,
  requestOptionsForPOST,
} from "./CommonServices";

const baseUrl = process.env.REACT_APP_APPHUB_API_ENDPOINT;
//const baseUrl = process.env.APPHUB_API_ENDPOINT;

export const deleteRole = async (id) => {
  var options = requestOptionsForDELETE();
  const response = fetch(baseUrl + "roles/" + id, options).then(async (res) => {
    const data = await res.json();
    if (!res.ok) {
      const error = ExtractErrorDetails(res, data);
      return Promise.reject(error);
    }
    return data;
  });
  return response;
};

export const postRole = async (data) => {
  var options = requestOptionsForPOST(data);
  const response = fetch(baseUrl + "roles", options)
    .then(async (res) => {
      const data = await res.json();
      // check for error response
      if (!res.ok) {
        // get error message from body or default to response statusText
        //const error = (data && data.message) || res.statusText;
        const error = ExtractErrorDetails(res, data);
        return Promise.reject(error);
      }
      return data;
    })
    //  .then(res => res.json())
    //  .then(
    //      (result) => {
    //          if (result !== null) {
    //             if (!result.ok) {
    //               // get error message from body or default to response statusText
    //               const error = (data && data.message) || result.statusText;
    //               return Promise.reject(error);
    //             }
    //             return result
    //          }
    //      })
    .catch((error) => {
      return Promise.reject(error);
    });
  return response;
};

export const fetchRole = async (id) => {
  var options = requestOptionsForGET();
  const response = fetch(baseUrl + "roles/" + id, options).then((data) => {
    return data.json();
  });
  return response;
};

export const fetchPermissionView = async (id) => {
  var options = requestOptionsForGET();
  const response = fetch(baseUrl + "roles/PermissionView/" + id, options).then(
    (data) => {
      return data.json();
    }
  );
  return response;
};

export const patchRole = async (data) => {
  var options = requestOptionsForPATCH(data);
  const response = fetch(baseUrl + "roles/" + data.roleid, options).then(
    async (res) => {
      const data = await res.json();
      // check for error response
      if (!res.ok) {
        // get error message from body or default to response statusText
        const error = ExtractErrorDetails(res, data);
        return Promise.reject(error);
      }
      return data;
    }
  );
  // .then(res => res.json())
  // .then(
  //     (result) => {
  //         if (result !== null) {
  //             return result
  //         }
  //     }).catch(error=>(error));
  return response;
};

export const fetchRoleList = async (
  pageIndex = 1,
  pageSize = 5,
  sorting = [],
  filtering = []
) => {
  const sortstr =
    sorting === undefined || sorting.length === 0 ? "" : ExtractSortBy(sorting);
  const filterstr =
    filtering === undefined || filtering.length === 0
      ? ""
      : ExtractFilterBy(filtering);

  var options = requestOptionsForGET();
  //const response = CustomAnswers
  let querystr = "";
  querystr =
    "?PageNumber=" + pageIndex + "&Limit=" + pageSize + sortstr + filterstr;
  const response = fetch(baseUrl + "roles/" + querystr, options)
    .then(async (res) => {
      const data = await res.json();
      // check for error response
      if (!res.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || res.statusText;
        return Promise.reject(error);
      }
      return data;
    })
    .catch((error) => {
      console.error("There was an error!", error.toString());
    });
  return response;
};
