import { ExtractErrorDetails } from "./Errorservice";
import {
  ExtractSortBy,
  ExtractFilterBy,
  requestOptionsForGET,
  requestOptionsForPATCH,
  requestOptionsForDELETE,
  requestOptionsForPOST,
} from "./CommonServices";

const baseUrl = process.env.REACT_APP_APPHUB_API_ENDPOINT;
//const baseUrl = process.env.APPHUB_API_ENDPOINT;

export const deleteApplication = async (id) => {
  var options = requestOptionsForDELETE();
  const response = fetch(baseUrl + "applications/" + id, options).then(
    async (res) => {
      const data = await res.json();
      if (!res.ok) {
        const error = ExtractErrorDetails(res, data);
        return Promise.reject(error);
      }
      return data;
    }
  );
  return response;
};

export const postApplication = async (data) => {
  var options = requestOptionsForPOST(data);
  const response = await fetch(baseUrl + "applications", options)
    .then(async (res) => {
      const data = await res.json();
      // check for error response
      if (!res.ok) {
        const error = ExtractErrorDetails(res, data);
        return Promise.reject(error);
      }
      return data;
    })

    .catch((error) => {
      return Promise.reject(error);
    });
  return response;
};

export const fetchApplication = async (id) => {
  var options = requestOptionsForGET();

  const response = fetch(baseUrl + "applications/" + id, options).then(
    (data) => {
      return data.json();
    }
  );
  return response;
};

export const patchApplication = async (data) => {
  var options = requestOptionsForPATCH(data);

  const response = fetch(
    baseUrl + "applications/" + data.applicationid,
    options
  ).then(async (res) => {
    const data = await res.json();
    // check for error response
    if (!res.ok) {
      // get error message from body or default to response statusText
      const error = ExtractErrorDetails(res, data);
      return Promise.reject(error);
    }
    return data;
  });

  return response;
};

export const fetchApplicationList = async (
  pageIndex = 1,
  pageSize = 5,
  sorting = [],
  filtering = []
) => {
  const sortstr =
    sorting === undefined || sorting.length === 0 ? "" : ExtractSortBy(sorting);
  const filterstr =
    filtering === undefined || filtering.length === 0
      ? ""
      : ExtractFilterBy(filtering);

  var options = requestOptionsForGET();

  //const response = CustomAnswers
  let querystr = "";
  querystr =
    "?PageNumber=" + pageIndex + "&Limit=" + pageSize + sortstr + filterstr;
  const response = fetch(baseUrl + "Applications/" + querystr, options)
    .then(async (res) => {
      const data = await res.json();
      // check for error response
      if (!res.ok) {
        return data; //Promise.reject(error)
      }

      return data;
    })
    .catch((error) => {
      console.error("There was an error!", error.toString());
    });

  return response;
};
export const fetchApplicationsByUser = async () => {
  // try {

  var options = requestOptionsForGET();

  const response = await fetch(
    baseUrl + "Applications/GetApplicationsbyUserId",
    options
  );

  //.then((res) => {
  if (response.ok) {
    const data = await response.json();
    return { statusCode: response.status, data };
    // Result.success(data);
  } else {
    return { statusCode: response.status };
  }

  // });
  // return response;
};
