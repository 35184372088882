/* eslint-disable react-hooks/exhaustive-deps */
/**
 * This is an example Listing page that lives on the route '/list-example'
 */
import React, { useContext, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import KaizenTable from "@kaizen-ui/table";
import { fetchAdUserList } from "../../api";
import { Layout } from "../../layout";
import Button, { ButtonGroup } from "@kaizen-ui/button";
import "./styles.css";
import AccessibilityContext from "../../components/auth/UserPermissionContext";

import WithAuth from "../../components/auth/WithAuth";
import WithAdminUIAuth from "../../components/auth/WithAdminUIAuth";
import {
  getLogicalOperator,
  getColumnNames,
  getOperators,
} from "./adsearchoptions";
import ExcelExport from "../../components/Export/ExcelExport";
const Table = KaizenTable;

const AdUserList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [pageIndex, setPage] = useState(() => {
    // Check if there is data in sessionStorage
    const storedData = sessionStorage.getItem("pageIndex");
    return storedData ? JSON.parse(storedData) : 0;
  });

  const [pageSize, setSize] = useState(() => {
    // Check if there is data in sessionStorage
    const storedData = sessionStorage.getItem("pageSize");
    return storedData ? JSON.parse(storedData) : 50;
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isSearch, setIsSearch] = useState(true);

  const [sorting, setSorting] = useState([{ id: "modified_date", desc: true }]);

  const [filtering, setFiltering] = useState(() => {
    // Check if there is data in sessionStorage
    const storedData = sessionStorage.getItem("filtering");

    // Parse the stored data or set a default value
    return storedData
      ? JSON.parse(storedData)
      : [
          {
            id: "emailid",
            value: "",
            operatorval: "=",
            logicaloperator: "AND",
          },
          // Add more default objects as needed
        ];
  });

  const [totalResults, setTotalResults] = useState(0);
  const [fetching, setFetching] = useState(true);
  // const [searchResults, setSearchResults] = useState([]);
  const permissions = useContext(AccessibilityContext).filter(
    (x) => x.pagename === "user"
  )[0];

  const hiddenColumns = ["userid", "idpname"];
  const columns = [
    { accessor: "userid", Header: "ID" },
    { accessor: "emailid", Header: "mail" },
    { accessor: "companyname", Header: "Company" },
    { accessor: "pecid", Header: "Pec Id" },
    { accessor: "accessroles", Header: "Access Role(s)" },
    { accessor: "lastname", Header: "Last Name" },
    { accessor: "firstname", Header: "First Name" },
    { accessor: "countrycode", Header: "Country" },
    { accessor: "idpname", Header: "Idp Name" },
    { accessor: "productfamily", Header: "Product Family" },
    { accessor: "licensetype", Header: "License Type" },
    { accessor: "idmaccesscode", Header: "IDM Access Code" },
    { accessor: "username", Header: "User ID" },
    { accessor: "isenabledstatus", Header: "Enabled Status" },
    { accessor: "earrestricted", Header: "IS Restricted" },
    { accessor: "createddate", Header: "Created date" },
    { accessor: "modifieddate", Header: "Modified Date" },
    { accessor: "logindatetime", Header: "Last Login Date" },
    // { accessor: "idpid", Header: "Idp Id" },
    // { accessor: "starfleetid", Header: "Starfleet Id" },

    // { accessor: "isactive", Header: "Account Status" },
  ];

  const IsArrayEqual = (first, second) => {
    return JSON.stringify(second) === JSON.stringify(first);
  };

  const rowColorChange = (row) => {
    const userid = row.userid;
    if (userid % 2 === 0) {
      return false;
    } else return true;
  };

  const handleFetchData = useCallback(
    (params) => {
      const insorting = JSON.stringify(params.sortBy);
      const inSortby = JSON.parse(insorting);
      const infiltering = JSON.stringify(params.filters);
      const inFilterBy = JSON.parse(infiltering);
      //console.log('currentPage : '+currentPage)

      if (IsArrayEqual(inSortby, sorting) === false) {
        //console.log('SortBY is not equal')
        setSorting(inSortby);
        //  setIsSearch(true);
        sessionStorage.setItem("sorting", inSortby);
      }

      if (
        inFilterBy.length > 0 &&
        IsArrayEqual(inFilterBy, filtering) === false
      ) {
        let length = inFilterBy.length;
        if (inFilterBy[length - 1].operatorval === undefined) {
          //setIsSearch(true);
          inFilterBy[length - 1].operatorval = "contains";
        }
        if (inFilterBy[length - 1].logicaloperator === undefined) {
          inFilterBy[length - 1].logicaloperator = "AND";
        }
        if (filtering.length > 1) {
          let finalArray = updateArrays(filtering, inFilterBy);
          setFiltering(finalArray);
        } else {
          setFiltering(inFilterBy);
        }
      }
      if (
        pageIndex !== params.pageIndex + 1 &&
        currentPage !== params.pageIndex + 1
      ) {
        // console.log("before pageIndex :" + pageIndex);
        setIsSearch(true);
        setPage(params.pageIndex + 1);
        sessionStorage.setItem("pageIndex", pageIndex);
        //console.log('after pageIndex :' + pageIndex)
      }
      if (pageSize !== params.pageSize) {
        //console.log('before pageSize :' + pageIndex)
        setSize(params.pageSize);
        setIsSearch(true);
        sessionStorage.setItem("pageSize", params.pageSize);
      }
    },
    [currentPage, pageIndex, pageSize, sorting, filtering]
  );

  // Default values for columns in array2
  const defaultValues = {
    operatorval: "=",
    logicaloperator: "AND",
  };

  const updateArrays = (array1, array2) => {
    const updatedArray = array1.map((item1) => {
      const matchingItem = array2.find(
        (item2) => item2.id === item1.id
        //&& item2.logicaloperator === "AND"
      );

      if (matchingItem) {
        // If there's a match, update properties
        return { ...item1, ...matchingItem };
      }

      // If there's no match, add default values from array2
      return { ...item1, ...defaultValues };
    });

    const nonMatchingItems = array2.filter(
      (item2) =>
        !array1.some(
          (item1) => item1.id === item2.id
          // && "AND" === item2.logicaloperator
        )
    );

    // Concatenate non-matching items with default values to the updated array
    const finalArray = [
      ...updatedArray,
      ...nonMatchingItems.map((item2) => ({ ...defaultValues, ...item2 })),
    ];

    // Do something with the final array, e.g., set it to state
    // setYourState(finalArray);
    return finalArray;
  };

  const handleSearch = () => {
    setIsSearch(true);
    if (pageIndex > 0) {
      let params = {
        filters: filtering,
        pageIndex: 0,
        pageSize: 50,
        sortBy: [],
      };

      handleFetchData(params, true);
      sessionStorage.setItem("filtering", JSON.stringify(filtering));
    }
  };
  const clearSearch = () => {
    sessionStorage.removeItem("filtering");
    sessionStorage.removeItem("pageIndex");
    sessionStorage.removeItem("pageSize");
    sessionStorage.removeItem("sorting");

    setIsSearch(true);
    var updatedArray1 = [
      {
        id: "emailid",
        value: "",
        operatorval: "=",
        logicaloperator: "AND",
      },
    ];

    setFiltering(updatedArray1);
    if (pageIndex > 0) {
      let params = {
        filters: filtering,
        pageIndex: 0,
        pageSize: 50,
        sortBy: [],
      };
      sessionStorage.setItem("filtering", JSON.stringify(filtering));
      sessionStorage.setItem("pageIndex", pageIndex);
      sessionStorage.setItem("pageSize", pageSize);
      sessionStorage.setItem("sorting", sorting);

      handleFetchData(params);
    }
    //onSearch(updatedArray1);
  };

  const addRow = () => {
    setFiltering([
      ...filtering,
      {
        id: "emailid",
        value: "",
        operatorval: "=",
        logicaloperator: "AND",
      },
    ]);
    setIsSearch(false);
  };

  const removeRow = (index) => {
    const updatedCriteria = [...filtering];
    updatedCriteria.splice(index, 1);
    setFiltering(updatedCriteria);
    setIsSearch(false);
  };

  const handleCriteriaChange = (e, index) => {
    if (e != null && e !== undefined) {
      const updatedCriteria = [...filtering];
      updatedCriteria[index].value = e.target.value;
      setFiltering(updatedCriteria);
      setIsSearch(false);
    }
  };

  const handleIdChange = (e, index) => {
    if (e != null && e !== undefined) {
      const updatedCriteria = [...filtering];
      updatedCriteria[index].id = e.target?.value || "";
      //  sessionStorage.setItem("filtering", JSON.stringify(updatedCriteria));
      setFiltering(updatedCriteria);
      setIsSearch(false);

      // e.preventDefault();
    }
  };

  const handleOperatorChange = (e, index) => {
    if (e != null && e !== undefined) {
      const updatedCriteria = [...filtering];
      updatedCriteria[index].operatorval = e.target?.value || "";
      setFiltering(updatedCriteria);
      // e.preventDefault();
      setIsSearch(false);
    }
  };

  const handleLogicalOperatorChange = (e, index) => {
    if (e != null && e !== undefined) {
      const updatedCriteria = [...filtering];
      updatedCriteria[index].logicaloperator = e.target?.value || "";
      setFiltering(updatedCriteria);
      setIsSearch(false);
      // e.preventDefault();
    }
  };

  useEffect(() => {
    if (permissions?.read === false) {
      navigate("/unauthorized");
    }

    if (pageIndex > 0 && isSearch) {
      setFetching(true);

      fetchAdUserList(pageIndex, pageSize, sorting, filtering).then((data) => {
        setData(data.contents);
        setCurrentPage(pageIndex);
        setTotalPages(Math.ceil(data.resultCount / pageSize));
        setTotalResults(data.resultCount);
        setFetching(false);
      });
      setIsSearch(false);
    }
    //}, []);
  }, [
    pageIndex,
    pageSize,
    sorting,
    filtering,
    isSearch,
    permissions?.read,
    navigate,
  ]);
  if (permissions?.read === false) {
    navigate("/unauthorized");
  }

  if (permissions?.read) {
    return (
      <Layout
        key="layuser"
        title="User List"
        headerChildren={
          <>
            {permissions?.create ? (
              <ButtonGroup key="btngrpuser">
                <ExcelExport filtering={filtering}></ExcelExport>
                <Button
                  key="btncreateuser"
                  icon={{ name: "ActionsCircleAdd", variant: "solid" }}
                  onClick={() => {
                    navigate("/user/0");
                  }}
                >
                  Create User
                </Button>
              </ButtonGroup>
            ) : (
              <div></div>
            )}
          </>
        }
      >
        <div>
          <table className="search-table">
            <tr>
              <td>
                {filtering.map((criteria, index) => (
                  <div key={index}>
                    <table className="search-table">
                      <tr>
                        {index !== 0 ? (
                          <td>
                            <select
                              style={{ height: "35px", width: "70px" }}
                              value={criteria.logicaloperator}
                              onChange={(e) =>
                                handleLogicalOperatorChange(e, index)
                              }
                            >
                              {getLogicalOperator().map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </td>
                        ) : (
                          <td></td>
                        )}

                        <td>
                          <select
                            style={{ height: "35px", width: "220px" }}
                            value={criteria.id}
                            onChange={(e) => handleIdChange(e, index)}
                          >
                            {getColumnNames().map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </td>

                        <td>
                          <select
                            style={{ height: "35px", width: "170px" }}
                            value={criteria.operatorval}
                            onChange={(e) => handleOperatorChange(e, index)}
                          >
                            {getOperators().map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            height="45px"
                            style={{ height: "35px" }}
                            value={criteria.value}
                            onChange={(e) => handleCriteriaChange(e, index)}
                          />

                          {"  "}
                        </td>
                        <td>
                          <button onClick={addRow}>
                            <svg
                              width="20"
                              height="20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <line
                                x1="2"
                                y1="10"
                                x2="18"
                                y2="10"
                                stroke="black"
                                strokeWidth="3"
                              />
                              <line
                                x1="10"
                                y1="2"
                                x2="10"
                                y2="18"
                                stroke="black"
                                strokeWidth="3"
                              />
                            </svg>
                          </button>{" "}
                          {index !== 0 ? (
                            <button onClick={() => removeRow(index)}>
                              <svg
                                width="20"
                                height="20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <line
                                  x1="2"
                                  y1="10"
                                  x2="18"
                                  y2="10"
                                  stroke="black"
                                  strokeWidth="3"
                                />
                              </svg>
                            </button>
                          ) : (
                            <span></span>
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>
                ))}
              </td>
              <td style={{ verticalAlign: "top" }}>
                <Button
                  css={{ color: "black" }}
                  size="standard"
                  tone="secondary"
                  onClick={handleSearch}
                  className="dashboard-header-primary dashboard-header-frame-1"
                >
                  Search
                </Button>
              </td>
              <td style={{ verticalAlign: "top" }}>
                <Button
                  css={{ color: "black" }}
                  className="dashboard-header-primary dashboard-header-frame-1"
                  size="standard"
                  tone="secondary"
                  onClick={clearSearch}
                >
                  Clear
                </Button>
              </td>
            </tr>
          </table>
        </div>

        {/* <AdvancedSearchForm onSearch={handleSearch}></AdvancedSearchForm> */}
        <Table
          key="tbluser"
          initialState={{
            pageSize: pageSize,
            pageIndex: pageIndex,
            hiddenColumns: hiddenColumns,
          }}
          paginationPageSizeOptions={[
            { label: "5", value: 5 },
            { label: "10", value: 10 },
            { label: "25", value: 25 },
            { label: "50", value: 50 },
            { label: "100", value: 100 },
            { label: "200", value: 200 },
          ]}
          rowOnClick={({ values }) => navigate(`/user/${values.userid}`)}
          //rowActions={[{ "icon": {"name": "ActionsCircleDelete","variant": "solid"},"itemStyle": "critical","label": "Delete Custom Response", onClick:(values)=>{deleteCustomResponse(values)}}]}
          // @ts-ignore
          columns={columns}
          data={data}
          pageCount={totalPages}
          totalResults={totalResults}
          fetchData={handleFetchData}
          fetching={fetching}
          autoResetPage={false}
          autoResetSortBy={false}
          disableColumnHiding={true}
          disableExport={false}
          disableGlobalFilter={true}
          disableFilters={true}
          //rowActions={getRowActionDetails}
          getRowProps={(row) => ({
            style: {
              background: rowColorChange(row) ? "" : "grey",
            },
          })}
        />
      </Layout>
    );
  }
};

export default WithAuth(WithAdminUIAuth(AdUserList));
