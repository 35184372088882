import "./styles.css";
import React, { useEffect } from "react";
export default function ServerError() {
  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
  }, []);
  return (
    <div className="unauthorized-page-container">
      <div className="unauthorized-page-content">
        <div
          style={{
            dispplay: "flex",
            justifyContent: "space-around",
            flexDirection: "row",
            marginBottom: "20px",
          }}
        >
          <p style={{ fontSize: "40px", margin: "0px", fontWeight: "bold" }}>
            Server Error
          </p>
          <br></br>
          <p>The API server is not up or the request could not be completed.</p>
        </div>
      </div>
    </div>
  );
}
