import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isTokenExpired } from "../../api/CommonServices";
import { renewAccessTokenCurrentUser } from "../../common/renewaccesstoken";
const WithAuth = (WrappedComponent) => {
  return function Hoc(props) {
    const navigate = useNavigate();
    useEffect(() => {
      const IsUserLoggedIn = localStorage.getItem("IsUserLoggedIn");
      if (IsUserLoggedIn !== "true" && IsUserLoggedIn !== "inprogress") {
        navigate("/login");
      }
      if (isTokenExpired()) {
        renewAccessTokenCurrentUser();
      }
    }, []);

    return <WrappedComponent {...props} />;
  };
};
export default WithAuth;
