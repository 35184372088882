import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CheckUserIsInternal } from "../../api";
const WithAdminUIAuth = (WrappedComponent) => {
  return function Hoc(props) {
    const navigate = useNavigate();
    useEffect(() => {
      const IsUserLoggedIn = localStorage.getItem("IsUserLoggedIn");
      if (IsUserLoggedIn !== "true" && IsUserLoggedIn !== "inprogress") {
        navigate("/login");
      } else {
        let data = CheckUserIsInternal();
        if (!data) {
          navigate("/serverError");
        } else if (data.status === "unauthorized") {
          navigate("/unauthorized");
        } else if (data.status === "internalServerError") {
          navigate("/internalServerError");
        } else if (data.status === "error") {
          navigate("/serverError");
        }
      }
    }, []);

    return <WrappedComponent {...props} />;
  };
};
export default WithAdminUIAuth;
