//import { InvalidTokenError, jwtDecode } from "jwt-decode";
import jwt_decode from "jwt-decode";
export function ConvertToDate(val) {
  //console.log(val)
  if (val === null || val === undefined) return val;
  if (typeof val === "object") {
    return val;
  }
  let dateval = val;
  if (dateval !== undefined) {
    const datevalarr = val.split("-");
    const newdate = new Date(
      Number(datevalarr[0]),
      Number(datevalarr[1]) - 1,
      Number(datevalarr[2])
    );
    return newdate;
  }
  return undefined;
}
export function ConvertToDateString(val, whichformat = "UI") {
  //console.log(val)
  if (val === null || val === undefined) return val;
  if (typeof val === "object") {
    const newdate = val;
    return whichformat === "UI"
      ? (newdate.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          newdate.getDate().toString().padStart(2, "0") +
          "-" +
          newdate.getFullYear()
      : newdate.getFullYear() +
          "-" +
          (newdate.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          newdate.getDate().toString().padStart(2, "0");
  }
  let dateval = val;
  if (dateval !== undefined) {
    const datevalarr = val.split("-");
    const newdate = new Date(
      Number(datevalarr[0]),
      Number(datevalarr[1]) - 1,
      Number(datevalarr[2])
    );
    return whichformat === "UI"
      ? (newdate.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          newdate.getDate().toString().padStart(2, "0") +
          "-" +
          newdate.getFullYear()
      : newdate.getFullYear() +
          "-" +
          (newdate.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          newdate.getDate().toString().padStart(2, "0");
  }
  return null;
}
export function GetTodayDateString() {
  const newdate = new Date();
  return (
    newdate.getFullYear() +
    "-" +
    (newdate.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    newdate.getDate().toString().padStart(2, "0")
  );
}

export function CompareDifferences(firstArray, secondArray, prop1) {
  return firstArray.filter((obj1) => {
    return !secondArray.some((obj2) => {
      return obj1[prop1] === obj2[prop1];
    });
  });
}
export function CompareMatches(firstArray, secondArray, prop1) {
  return firstArray.filter((obj1) => {
    return secondArray.some((obj2) => {
      return obj1[prop1] === obj2[prop1];
    });
  });
}

export const SetLoggedinUser = () => {
  const loggedInUser = localStorage.getItem("adal.idtoken");
  const token = loggedInUser !== null ? jwt_decode(loggedInUser) : "";
  //const username: string| null = token.unique_name.replace('@nvidia.com', '');
  localStorage.setItem("user", token?.unique_name?.replace("@nvidia.com", ""));
  localStorage.setItem("email", token?.unique_name);
  localStorage.setItem("name", token?.name);
  localStorage.setItem("showall", "true");
};
export const GetLoggedinUser = () => {
  return localStorage.getItem("name");
};
export const GetLoggedinUserEmail = () => {
  return localStorage.getItem("email");
};
export const GetLoggedinUsertoken = () => {
  return localStorage.getItem("adal.idtoken");
};

export const GetSelectedPermissions = (items) => {
  const options = [];
  items.forEach(function (item) {
    if (item.readChecked === true) options.push(item.read);

    if (item.createChecked === true) options.push(item.create);

    if (item.updateChecked === true) options.push(item.update);

    if (item.deleteChecked === true) options.push(item.delete);
  });
  return options;
};
