import { React } from "react";
import { useState, useEffect } from "react";
import { Layout } from "../../layout";
import Button, { ButtonGroup } from "@kaizen-ui/button";
import WithAuth from "../../components/auth/WithAuth";
import WithAdminUIAuth from "../../components/auth/WithAdminUIAuth";
import { Table, TableRow, TableHeader, TableDataCell } from "@kui-react/table";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Form from "react-bootstrap/Form";
import axios, { Axios } from "axios";
import { Checkbox } from "@kui-react/checkbox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const baseUrl = process.env.REACT_APP_APPHUB_API_ENDPOINT;

const BulkUploadMapping = () => {
  const [rowsData, setRowsData] = useState([,]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [isMapped, setIsMap] = useState(false);
  const [isMondatory, setMondatory] = useState(false);
  const [defaultValue, setDefaultValue] = useState("");
  const [mappings, setMappaing] = useState([
    {
      FileColumnName: "",
      DBColumnName: "",
      ColumnDataType: "=",
      DefaultValue: "",
      IsMapped: false,
      IsMondatory: false,
    },
  ]);

  useEffect(() => {
    // console.log("loaded");
    if (isLoaded === false) {
      InitializePage();
    }
  });

  const handleDataTypeChange = (e, index) => {
    const mappedData = [...rowsData];
    mappedData[1][index].columnDataType = e.target.value;
    setRowsData(mappedData);
  };

  const handleFileColumnChange = (e, index) => {
    const mappedData = [...rowsData];
    mappedData[1][index].fileColumnName = e.target.value;
    setRowsData(mappedData);
  };

  const handleDefaultValueChange = (e, index) => {
    const mappedData = [...rowsData];
    mappedData[1][index].columnValue = e.target.value;
    setRowsData(mappedData);
  };

  const handleIsMappedChange = (evn, index) => {
    const options = [...rowsData];
    if (evn.target.value === "true") options[1][index].isMapped = false;
    else options[1][index].isMapped = true;

    setRowsData(options);
  };

  const handleIsMondatoryCheckChange = (evn, index) => {
    const options = [...rowsData];
    if (evn.target.value === "true") options[1][index].isMondatory = false;
    else options[1][index].isMondatory = true;

    setRowsData(options);
  };

  const SaveMapping = () => {
    var token = localStorage.getItem("ROCP_idToken");
    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "*/*",
    };

    axios
      .post(
        baseUrl + "UsersUpload/SaveMapping",
        JSON.stringify(rowsData[1].filter((element) => element)),
        {
          headers: headers,
        }
      )
      .then((response) => {
        alert("Mapping saved successfully.");
      })
      .catch((error) => {
        console.error("There was an error!", error);
        alert("Role updation failed. Please check file and selected option.");
      });
  };

  const InitializePage = () => {
    setIsLoaded(true);
    try {
      var token = localStorage.getItem("ROCP_idToken");
      const headers = {
        Authorization: "Bearer " + token,
        Accept: "*/*",
      };
      const selectionInput = [,];
      axios
        .get(baseUrl + "UsersUpload", {
          headers: headers,
        })
        .then((response) => {
          response.data.columnMappingDTO.map(
            (item) => selectionInput.push(item),
            setRowsData([...rowsData, selectionInput])
          );
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <Layout
      key="laycustomresponse"
      title="User Migration"
      headerChildren={
        <ButtonGroup key="btngrpcreateresponse">
          <Button
            key="btncreateresponse"
            icon={{ name: "ActionsCircleAdd", variant: "solid" }}
          >
            User Upload Mapping
          </Button>
        </ButtonGroup>
      }
    >
      <div>
        <div className="container">
          <Button onClick={SaveMapping}>Save Mapping</Button>
          {/* <Modal   open={open}   onClose={handleClose}   aria-labelledby="parent-modal-title"   aria-describedby="parent-modal-description" >
   <Box sx={{ ...style, width: 400 }}>
   <Form>
           <Form.Group controlId="fileTypeSelect" aria-readonly>
               <Form.Control as="select" selected={1}>
                   <option value="NotSelected">Select Action Type</option>
                   <option value="1" >New Users</option>
                   <option value="2">Update Existing Users</option>
               </Form.Control>
           </Form.Group>
           <br />
           <Form.Group controlId="fileTypeSelect">
               <Form.Control as="select" selected={1}>
               {rowsData[1]?.map(({ fileColumnName, isSelected }, index) => (
                                    <option
                                      value={fileColumnName}
                                      selected={isSelected === true}
                                    >
                                      {fileColumnName}
                                    </option>
                                  )
                                )}
               </Form.Control>
           </Form.Group>
           <br/>
           {/* style={{visibility: sheetNoVisibility}} */}

          {/* </Form>
     <Button variant="primary" onClick={handleUploading}>
               Ok
           </Button>
   </Box>
 </Modal> */}

          <div className="table-responsive">
            <div className="row">
              <div className="col-sm-8">
                <Table>
                  <TableRow>
                    <TableHeader>DB Columns</TableHeader>
                    <TableHeader>CSV/ Excel Columns</TableHeader>
                    <TableHeader>Data Type </TableHeader>
                    <TableHeader>Default Value </TableHeader>
                    <TableHeader>Is Mapped</TableHeader>
                    <TableHeader>Is Mondatory</TableHeader>
                  </TableRow>

                  {rowsData[1]?.map((columns, index) => {
                    return (
                      <TableRow>
                        <TableDataCell> {columns.dbColumnName} </TableDataCell>
                        <TableDataCell>
                          <Form.Group
                            controlId="fileColumnSelection"
                            onChange={(e) => handleFileColumnChange(e, index)}
                          >
                            <Form.Control as="select" selected={1}>
                              {rowsData[1]?.map(
                                ({
                                  dbColumnName,
                                  fileColumnName,
                                  isSelected,
                                }) => (
                                  <option
                                    value={fileColumnName}
                                    selected={
                                      columns.dbColumnName === dbColumnName
                                    }
                                  >
                                    {fileColumnName}
                                  </option>
                                )
                              )}
                            </Form.Control>
                          </Form.Group>
                        </TableDataCell>

                        <TableDataCell>
                          <Form.Group controlId="columnDataType">
                            <Form.Control
                              as="select"
                              selected={1}
                              onChange={(e) => handleDataTypeChange(e, index)}
                            >
                              <option value="0">Select Data Type</option>
                              <option
                                value="Text"
                                selected={columns.columnDataType === "Text"}
                              >
                                Text
                              </option>
                              <option
                                value="Number"
                                selected={columns.columnDataType === "Number"}
                              >
                                Number
                              </option>
                              <option
                                value="Boolean"
                                selected={columns.columnDataType === "Boolean"}
                              >
                                Boolean
                              </option>
                              <option
                                value="DateTime"
                                selected={columns.columnDataType === "DateTime"}
                              >
                                Date Time
                              </option>
                            </Form.Control>
                          </Form.Group>
                        </TableDataCell>

                        <TableDataCell>
                          <input
                            type="text"
                            height="45px"
                            style={{ height: "35px" }}
                            value={
                              columns.columnValue === "null"
                                ? ""
                                : columns.columnValue
                            }
                            onChange={(e) => handleDefaultValueChange(e, index)}
                          />
                        </TableDataCell>

                        <TableDataCell>
                          <div className="input-group">
                            <div className="checkbox-inline">
                              <Checkbox
                                value={columns.isMapped}
                                checked={columns.isMapped}
                                id={columns.dbColumnName + "-" + "isMapped"}
                                onChange={(e) => handleIsMappedChange(e, index)}
                                name={
                                  columns.dbColumnName + "#" + columns.isMapped
                                }
                              />
                            </div>
                          </div>

                          {/* <Checkbox checked={isMapped} onChange={(e) => handleIsMappedChange(e,columns.dbColumnName)}/>  */}
                        </TableDataCell>

                        <TableDataCell>
                          <div className="input-group">
                            <div className="checkbox-inline">
                              <Checkbox
                                value={columns.isMondatory}
                                checked={columns.isMondatory}
                                id={columns.dbColumnName + "-" + "isMapped"}
                                onChange={(e) =>
                                  handleIsMondatoryCheckChange(e, index)
                                }
                                name={
                                  columns.dbColumnName +
                                  "#" +
                                  columns.isMondatory
                                }
                              />
                            </div>
                          </div>
                          {/* <Checkbox checked={columns.isMondatory} onChange={(e) => handleMondatoryChange(e,columns.dbColumnName)} />  */}
                          {/* <Checkbox checked={isMondatory} onChange={(e) => handleIsMondatoryCheckChange(e,index)} />  */}
                        </TableDataCell>

                        {/* <TableDataCell>
                            {" "}
                            <div>
                              <select
                                id="excelSelection"
                                class="form-select"
                                name={columns.name}
                                // onChange={handleUploading}
                              >
                                {columns.dataColumns.map(
                                  ({ name, isSelected }, index) => (
                                    <option
                                      value={name}
                                      selected={isSelected === true}
                                    >
                                      {name}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </TableDataCell> */}
                      </TableRow>
                    );
                  })}
                </Table>
              </div>
              <div className="col-sm-4"></div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WithAuth(WithAdminUIAuth(BulkUploadMapping));
