import { ExtractErrorDetails } from "./Errorservice";
import { requestOptionsForGET } from "./CommonServices";
const baseUrl = process.env.REACT_APP_APPHUB_API_ENDPOINT;
//const baseUrl = process.env.APPHUB_API_ENDPOINT;

export const postAdderrorlog = async (data) => {
  var options = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(baseUrl + "Errorlog/AddErrorLog", options)
    .then(async (res) => {
      const data = await res.json();
      // check for error response
      if (!res.ok) {
        const error = ExtractErrorDetails(res, data);
        return Promise.reject(error);
      }
      return data;
    })

    .catch((error) => {
      return Promise.reject(error);
    });
  return response;
};
export const postAddUserEmailLog = async (data) => {
  var options = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(baseUrl + "Errorlog/AddUserEmailLog", options)
    .then(async (res) => {
      const data = await res.json();
      // check for error response
      if (!res.ok) {
        const error = ExtractErrorDetails(res, data);
        return Promise.reject(error);
      }
      return data;
    })

    .catch((error) => {
      return Promise.reject(error);
    });
  return response;
};
export const postAutenticationsuccessemailllog = async (data) => {
  var options = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    baseUrl + "Errorlog/Addautenticationsuccessemailllog",
    options
  )
    .then(async (res) => {
      const data = await res.json();
      // check for error response
      if (!res.ok) {
        const error = ExtractErrorDetails(res, data);
        return Promise.reject(error);
      }
      return data;
    })

    .catch((error) => {
      return Promise.reject(error);
    });
  return response;
};

export const GetMaintenanceNotice = async () => {
  //var options = requestOptionsForGET();
  var options = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
    },
  };

  const response = fetch(baseUrl + "Errorlog/GetMaintenanceNotice", options)
    .then(async (res) => {
      const data = await res.json();
      if (!res.ok) {
        return data;
      }
      return data;
    })
    .catch((error) => {
      console.error("There was an error!", error.toString());
    });
  return response;
};
