/**
 * This is an example Listing page that lives on the route '/list-example'
 */
import React, { useContext, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import KaizenTable from "@kaizen-ui/table";
import { fetchAuditLogList, fetchAuditLogListFilter } from "../../api";
import { Layout } from "../../layout";
import AccessibilityContext from "../../components/auth/UserPermissionContext";
import "./styles.css";
import WithAuth from "../../components/auth/WithAuth";
import DatePicker from "react-datepicker";
import Button, { ButtonGroup } from "@kaizen-ui/button";
import { ConvertToDateString, ConvertToDate } from "../../utils";
import {
  getLogicalOperator,
  getColumnNames, getAuditColumnNames,
  getOperators,
} from "../user/adsearchoptions";
// Kaizen table has small typing bug at the moment. This ensures columns and
// data props are registered correctly
const Table = KaizenTable;

const AuditLogList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [pageIndex, setPage] = useState(0);
  const [pageSize, setSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  //const [sorting, setSorting] = useState([{ id: "modified_date", desc: true }]);
  //const [filtering, setFiltering] = useState([{ id: "test", value: "test" }]);
  const [totalResults, setTotalResults] = useState(0);
  const [showmine, setShowMine] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [isSearch, setIsSearch] = useState(true);
  const [sorting, setSorting] = useState([{ id: "modified_date", desc: true }]);
  const [newFieldFilter, setnewFieldFilter] = useState([]);

  const [filtering, setFiltering] = useState(() => {
    // Check if there is data in sessionStorage
    const storedData = sessionStorage.getItem("log-filtering");

    // Parse the stored data or set a default value
    return storedData
      ? JSON.parse(storedData)
      : [
          {
            id: "emailid",
            value: "",
            operatorval: "=",
            logicaloperator: "AND",
          },
          // Add more default objects as needed
        ];
  });

  const permissions = useContext(AccessibilityContext).filter(
    (x) => x.pagename === "auditlog"
  )[0];

  const hiddenColumns = ["auditid", "username"];

  const DateConverter = (cellprop) => {
    const dateval = ConvertToDateString(cellprop.value);
    return dateval;
  };

  const DateFilter = (columnprop) => {
    const column = columnprop.column;
    const dateval = ConvertToDate(column.filterValue);
    return React.createElement(DatePicker, {
      selected: dateval,
      dateFormat: "MM-dd-yyyy",
      onChange: (val) => {
        if (val) {
          const valdate = new Date(val);
          const valstr =
            valdate.getFullYear() +
            "-" +
            (valdate.getMonth() + 1) +
            "-" +
            valdate.getDate();
          column.setFilter(valstr);
        }
      },
    });
  };

  const columns = [
    { accessor: "auditid" },
    // { accessor: "audittype", Header: "Audittype" },
    // { accessor: "recordtype", Header: "Table Name" },
    {
      accessor: "auditdate",
      // Filter: DateFilter,
      // Cell: DateConverter,
      Header: "auditdate",
    },
    { accessor: "comment", Header: "Comment" },
    { accessor: "action", Header: "Action" },
    { accessor: "fields", Header: "Fields" },
    { accessor: "updatedby", Header: "Modified By" },
    { accessor: "emailid", Header: "Email" },
    { accessor: "oldvalue", Header: "Oldvalue" },
    { accessor: "newvalue", Header: "New Value" },
    { accessor: "username", Header: "User Name" },
  ];
  const IsArrayEqual = (first, second) => {
    return JSON.stringify(second) === JSON.stringify(first);
  };

  const rowColorChange = (row) => {
    const auditid = row.auditid;
    if (auditid % 2 === 0) {
      return false;
    } else return true;
  };

  // const getRowActionDetails = (values:any) => {
  //   return [{ "icon": {"name": "ActionsCircleDelete","variant": "solid"},"itemStyle": "critical","label": "Delete Custom Response", onClick:()=>{deleteCustomResponse(values.answer_id)}}]
  // }

  const handleFetchData = useCallback(
    (params) => {
      const insorting = JSON.stringify(params.sortBy);
      const inSortby = JSON.parse(insorting);
      const infiltering = JSON.stringify(params.filters);
      const inFilterBy = JSON.parse(infiltering);
      //console.log('currentPage : '+currentPage)
      if (IsArrayEqual(inSortby, sorting) === false) {
        //console.log('SortBY is not equal')
        setSorting(inSortby);
      }
      if (IsArrayEqual(inFilterBy, filtering) === false) {
        //console.log('Filter is not equal')
        //setFiltering(inFilterBy);
      }
      if (
        pageIndex !== params.pageIndex + 1 &&
        currentPage !== params.pageIndex + 1
      ) {
        //console.log('before pageIndex :' + pageIndex)
        setIsSearch(true);
        setPage(params.pageIndex + 1);
        //console.log('after pageIndex :' + pageIndex)
      }
      if (pageSize !== params.pageSize) {
        //console.log('before pageSize :' + pageIndex)
        setSize(params.pageSize);
        setIsSearch(true);
      }
    },
    [currentPage, pageIndex, pageSize, sorting, filtering]
  );
  const handleSearch = () => {
    // const existingSearch = filtering.filter(filter => filter.id.includes("newvalue"));

    // var updatedFiltering;
    // if (existingSearch.length > 0) {
    //    updatedFiltering = filtering.map(filter => 
    //     filter.id.includes("newvalue")  
    //       ? { ...filter, id: "newvalue" } 
    //       : filter
    //   );
    // }

    setIsSearch(true);
    if (pageIndex > 0) {
      let params = {
        filters: filtering,
        pageIndex: 0,
        pageSize: 50,
        sortBy: [],
      };

      handleFetchData(params, true);
      sessionStorage.setItem("log-filtering", JSON.stringify(filtering));
    }
  };
  const clearSearch = () => {
    sessionStorage.removeItem("log-filtering");
    sessionStorage.removeItem("log-pageIndex");
    sessionStorage.removeItem("log-pageSize");
    sessionStorage.removeItem("log-sorting");

    setIsSearch(true);
    var updatedArray1 = [
      {
        id: "emailid",
        value: "",
        operatorval: "=",
        logicaloperator: "AND",
      },
    ];

    setFiltering(updatedArray1);
    setnewFieldFilter([]);
    if (pageIndex > 0) {
      let params = {
        filters: filtering,
        pageIndex: 0,
        pageSize: 50,
        sortBy: [],
      };
      sessionStorage.setItem("log-filtering", JSON.stringify(filtering));
      sessionStorage.setItem("log-pageIndex", pageIndex);
      sessionStorage.setItem("log-pageSize", pageSize);
      sessionStorage.setItem("log-sorting", sorting);

      handleFetchData(params);
    }
    //onSearch(updatedArray1);
  };

  const addRow = () => {
    setFiltering([
      ...filtering
      ,
      {
        id: "emailid",
        value: "",
        operatorval: "=",
        logicaloperator: "AND",
      },
    ]);

    const existingFilters = filtering.filter(filter => filter.id === "fields");

    if (existingFilters.length > 0) {
      existingFilters.forEach(existingFilter => {
        console.log("Value of filter with ID 'fields':", existingFilter.value);

        // Check if newFieldFilter already has an entry for the existing filter value
        const valueFilter = newFieldFilter.find(filter => filter.label === existingFilter.value);

        if (!valueFilter && existingFilter.value.length > 0) {
          setnewFieldFilter(prev => [
            ...prev,
            {
              label: existingFilter.value, // Use the actual value
              value: "newvalue"
            }
          ]);
        } else { 
          console.log("Filter already exists, not adding.");
        }
      });
    }
    
    setIsSearch(false);
  };

  const removeRow = (index) => {
    const updatedCriteria = [...filtering];
    updatedCriteria.splice(index, 1);
    setFiltering(updatedCriteria);

    const existingFilters = updatedCriteria.filter(filter => filter.id === "fields");

    if (existingFilters.length > 0) {
      existingFilters.forEach(existingFilter => {
        console.log("Value of filter with ID 'fields':", existingFilter.value);

        // Check if newFieldFilter already has an entry for the existing filter value
        const valueFilter = newFieldFilter.find(filter => filter.label === existingFilter.value);

        if (!valueFilter) {
          setnewFieldFilter(prev => [
            ...prev,
            {
              label: existingFilter.value, // Use the actual value
              value: "newvalue" + existingFilter.value
            }
          ]);
        } else { 
          console.log("Filter already exists, not adding.");
          // Check if newFieldFilter already has an entry for the existing filter value
          const updatedFieldFilter = newFieldFilter.filter(filter => filter.label !== existingFilter.value);

          setnewFieldFilter(updatedFieldFilter);

        }

        
      });
    } 
    else {
        // If existingFilter is not found, remove the corresponding entry from newFieldFilter      
          setnewFieldFilter([]);      
    }

    setIsSearch(false);
  };

  const handleCriteriaChange = (e, index) => {
    if (e != null && e !== undefined) {
      const updatedCriteria = [...filtering];
      updatedCriteria[index].value = e.target.value;
      setFiltering(updatedCriteria);
      setIsSearch(false);
    }
  };

  const handleIdChange = (e, index) => {
    if (e != null && e !== undefined) {
      const updatedCriteria = [...filtering];
      updatedCriteria[index].id = e.target?.value || "";
      //  sessionStorage.setItem("filtering", JSON.stringify(updatedCriteria));

      if(updatedCriteria[index].id === "Accessroles"){
        updatedCriteria[index].id = "Comment";
      }

      setFiltering(updatedCriteria);
      setIsSearch(false);

      // e.preventDefault();
    }
  };

  const handleOperatorChange = (e, index) => {
    if (e != null && e !== undefined) {
      const updatedCriteria = [...filtering];
      updatedCriteria[index].operatorval = e.target?.value || "";
      setFiltering(updatedCriteria);
      // e.preventDefault();
      setIsSearch(false);
    }
  };

  const handleLogicalOperatorChange = (e, index) => {
    if (e != null && e !== undefined) {
      const updatedCriteria = [...filtering];
      updatedCriteria[index].logicaloperator = e.target?.value || "";
      setFiltering(updatedCriteria);
      setIsSearch(false);
      // e.preventDefault();
    }
  };


  useEffect(() => {
    if (permissions?.read === false) {
      navigate("/unauthorized");
    }
    if (pageIndex > 0 && isSearch) {
      localStorage.setItem("showall", (!showmine).toString());
      setFetching(true);
      fetchAuditLogListFilter(pageIndex, pageSize, sorting, filtering).then(
        (data) => {
          setData(data.contents);
          setCurrentPage(pageIndex);
          setTotalPages(Math.ceil(data.resultCount / pageSize));
          setTotalResults(data.resultCount);
          setShowMine(showmine);
          setFetching(false);
        }
      );
      setIsSearch(false);
      const existingFilters = filtering.filter(filter => filter.id === "fields");

    if (existingFilters.length > 0) {
      existingFilters.forEach(existingFilter => {
        console.log("Value of filter with ID 'fields':", existingFilter.value);

        // Check if newFieldFilter already has an entry for the existing filter value
        const valueFilter = newFieldFilter.find(filter => filter.label === existingFilter.value);

        if (!valueFilter && existingFilter.value.length > 0) {
          setnewFieldFilter(prev => [
            ...prev,
            {
              label: existingFilter.value, // Use the actual value
              value: "newvalue"
            }
          ]);
        } else { 
          console.log("Filter already exists, not adding.");
        }
      });
    }  
    }
  }, [
    pageIndex,
    pageSize,
    sorting,
    filtering,
    showmine,
    isSearch,
    permissions?.read,
    navigate,
  ]);
  if (permissions?.read === false) {
    navigate("/unauthorized");
  }

  if (permissions?.read) {
    return (
      <Layout key="layrole" title="Audit Log" headerChildren={<></>}>
        <div>
        <table className="search-table">
            <tr>
              <td>
                {filtering.map((criteria, index) => (
                  <div key={index}>
                    <table className="search-table">
                      <tr>
                        {index !== 0 ? (
                          <td>
                            <select
                              style={{ height: "35px", width: "70px" }}
                              value={criteria.logicaloperator}
                              onChange={(e) =>
                                handleLogicalOperatorChange(e, index)
                              }
                            >
                              {getLogicalOperator().map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </td>
                        ) : (
                          <td></td>
                        )}

                        <td>
                          <select
                            style={{ height: "35px", width: "220px" }}
                            value={criteria.id}
                            onChange={(e) => handleIdChange(e, index)}
                          >
                            {getAuditColumnNames().map((option, optionIndex) => (
                              <option key={optionIndex} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                            {Array.isArray(newFieldFilter) && newFieldFilter.map((option, optionIndex) => ( 
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                            ))}
                          </select>
                        </td>

                        <td>
                          <select
                            style={{ height: "35px", width: "170px" }}
                            value={criteria.operatorval}
                            onChange={(e) => handleOperatorChange(e, index)}
                          >
                            {getOperators().map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            height="45px"
                            style={{ height: "35px" }}
                            value={criteria.value}
                            onChange={(e) => handleCriteriaChange(e, index)}
                          />

                          {"  "}
                        </td>
                        <td>
                          <button onClick={addRow}>
                            <svg
                              width="20"
                              height="20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <line
                                x1="2"
                                y1="10"
                                x2="18"
                                y2="10"
                                stroke="black"
                                strokeWidth="3"
                              />
                              <line
                                x1="10"
                                y1="2"
                                x2="10"
                                y2="18"
                                stroke="black"
                                strokeWidth="3"
                              />
                            </svg>
                          </button>{" "}
                          {index !== 0 ? (
                            <button onClick={() => removeRow(index)}>
                              <svg
                                width="20"
                                height="20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <line
                                  x1="2"
                                  y1="10"
                                  x2="18"
                                  y2="10"
                                  stroke="black"
                                  strokeWidth="3"
                                />
                              </svg>
                            </button>
                          ) : (
                            <span></span>
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>
                ))}
              </td>
              <td style={{ verticalAlign: "top" }}>
                <Button
                  css={{ color: "black" }}
                  size="standard"
                  tone="secondary"
                  onClick={handleSearch}
                  className="dashboard-header-primary dashboard-header-frame-1"
                >
                  Search
                </Button>
              </td>
              <td style={{ verticalAlign: "top" }}>
                <Button
                  css={{ color: "black" }}
                  className="dashboard-header-primary dashboard-header-frame-1"
                  size="standard"
                  tone="secondary"
                  onClick={clearSearch}
                >
                  Clear
                </Button>
              </td>
            </tr>
          </table>
        </div>

        <Table
          key="tblAuditLogs"
          initialState={{
            pageSize: pageSize,
            pageIndex: pageIndex,
            hiddenColumns: hiddenColumns,
          }}
          paginationPageSizeOptions={[
            { label: "5", value: 5 },
            { label: "10", value: 10 },
            { label: "25", value: 25 },
            { label: "50", value: 50 },
            { label: "100", value: 100 },
          ]}
          rowOnClick={({ values }) => navigate(`/auditlog/${values.auditid}`)}
          // @ts-ignore
          columns={columns}
          data={data}
          pageCount={totalPages}
          totalResults={totalResults}
          fetchData={handleFetchData}
          fetching={fetching}
          autoResetPage={false}
          autoResetSortBy={false}
          disableColumnHiding={true}
          disableExport={false}
          disableGlobalFilter={true}
          disableFilters={true}
          //rowActions={getRowActionDetails}
          getRowProps={(row) => ({
            style: {
              background: rowColorChange(row) ? "" : "grey",
            },
          })}
        />
      </Layout>
    );
  }
};

export default WithAuth(AuditLogList);
