import { ExtractErrorDetails } from "./Errorservice";
import {
  ExtractSortBy,
  ExtractFilterBy,
  requestOptionsForGET,
  requestOptionsForPATCH,
  requestOptionsForDELETE,
  requestOptionsForPOST,
} from "./CommonServices";

const baseUrl = process.env.REACT_APP_APPHUB_API_ENDPOINT;
//const baseUrl = process.env.APPHUB_API_ENDPOINT;

export const deletePermission = async (id) => {
  var options = requestOptionsForDELETE();
  const response = fetch(baseUrl + "permissions/" + id, options).then(
    async (res) => {
      const data = await res.json();
      if (!res.ok) {
        const error = ExtractErrorDetails(res, data);
        return Promise.reject(error);
      }
      return data;
    }
  );
  return response;
};

export const postPermission = async (data) => {
  var options = requestOptionsForPOST(data);
  const response = fetch(baseUrl + "permissions", options)
    .then(async (res) => {
      const data = await res.json();
      // check for error response
      if (!res.ok) {
        const error = ExtractErrorDetails(res, data);

        return Promise.reject(error);
      }
      return data;
    })
    //  .then(res => res.json())
    //  .then(
    //      (result) => {
    //          if (result !== null) {
    //             if (!result.ok) {
    //               // get error message from body or default to response statusText
    //               const error = (data && data.message) || result.statusText;
    //               return Promise.reject(error);
    //             }
    //             return result
    //          }
    //      })
    .catch((error) => {
      return Promise.reject(error);
    });
  return response;
};

export const fetchPermission = async (id) => {
  var options = requestOptionsForGET();

  const response = fetch(baseUrl + "permissions/" + id, options).then(
    (data) => {
      return data.json();
    }
  );
  return response;
};

export const patchPermission = async (data) => {
  var options = requestOptionsForPATCH(data);
  const response = fetch(
    baseUrl + "permissions/" + data.permissionid,
    options
  ).then(async (res) => {
    const data = await res.json();
    // check for error response
    if (!res.ok) {
      // get error message from body or default to response statusText
      const error = ExtractErrorDetails(res, data);
      return Promise.reject(error);
    }
    return data;
  });

  return response;
};

export const fetchPermissionList = async (
  pageIndex = 1,
  pageSize = 5,
  sorting = [],
  filtering = []
) => {
  const sortstr =
    sorting === undefined || sorting.length === 0 ? "" : ExtractSortBy(sorting);

  const filterstr =
    filtering === undefined || filtering.length === 0
      ? ""
      : ExtractFilterBy(filtering);

  var options = requestOptionsForGET();
  //const response = Permissions
  let querystr = "";
  querystr =
    "?PageNumber=" + pageIndex + "&Limit=" + pageSize + sortstr + filterstr;
  const response = fetch(baseUrl + "Permissions/" + querystr, options)
    .then(async (res) => {
      const data = await res.json();
      // check for error response
      if (!res.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || res.statusText;
        return Promise.reject(error);
      }
      return data;
    })
    .catch((error) => {
      console.error("There was an error!", error.toString());
    });
  return response;
};
