import { SetLoggedinUser } from "../common/utils";
import { renewAccessTokenCurrentUser } from "../common/renewaccesstoken";

export function ExtractSortBy(sorting) {
  if (sorting.length === 0) {
    return "";
  }
  const strarr = [];
  sorting.forEach((value) => {
    strarr.push(value.id + "&sortDirection=" + (value.desc ? " desc" : "asc"));
  });
  return "&sortName=" + strarr.join(",");
}

export function ExtractFilterBy(filtering) {
  if (filtering.length === 0) {
    return "";
  }
  const strarr = [];
  filtering.forEach((value) => {
    if (value.id === "emailid" || value.value.includes("+")) {
      strarr.push(value.id + "=" + encodeURIComponent(value.value));
    } else {
      strarr.push(value.id + "=" + value.value);
    }
  });
  return "&" + strarr.join("&");
}

export function ExtractFilterByForAuditLog(filtering) {
  if (filtering.length === 0) {
    return "";
  }
  const strarr = [];
  filtering.forEach((value) => {
    if (value.id === "emailid" || value.value.includes("+")) {
      strarr.push(value.id + "=" + encodeURIComponent(value.value));
    } else {
      if(value.id === "Companyname" || value.id === "Pecid" || value.id == "Lastname" || value.id == "Firstname"){
        value.id ="Comment"
      }
      strarr.push(value.id + "=" + value.value);
    }
  });
  return "&" + strarr.join("&");
}

export function ExtractUserFilterBy(filtering) {
  if (filtering.length === 0) {
    return "";
  }
  const strarr = [];

  filtering.forEach((value) => {
    if (
      value.value !== "" &&
      value.value !== undefined &&
      value.value !== null
    ) {
      if (value.id === "emailid" || value.value.includes("+")) {
        strarr.push(
          value.columnName +
            value.operator +
            encodeURIComponent(value.value) +
            value.logicalOperator
        );
      } else {
        strarr.push(
          value.columnName +
            value.operator +
            value.value +
            value.logicalOperator
        );
      }
    }
  });
  if (strarr.length > 0) return "&" + strarr.join("&");
  return "";
  //return convertToQueryString(filtering);
}

export function requestOptionsForGET() {
  let loggedinuser = localStorage.getItem("email");
  let token = localStorage.getItem("ROCP_idToken");
  if (isTokenExpired()) {
    renewAccessTokenCurrentUser();
    loggedinuser = localStorage.getItem("email");
    token = localStorage.getItem("ROCP_idToken");
  }

  if (loggedinuser === "") {
    SetLoggedinUser();
    loggedinuser = localStorage.getItem("user") || "";
    if (loggedinuser === "") return;
  }

  if (loggedinuser === undefined || loggedinuser === null) return;
  return {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
}

export function requestOptionsForPOST(inObj) {
  let token = localStorage.getItem("ROCP_idToken");
  if (isTokenExpired()) {
    renewAccessTokenCurrentUser();

    token = localStorage.getItem("ROCP_idToken");
  }

  return {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(inObj),
  };
}
export function requestOptionsForPOSTFileData(inObj) {
  let token = localStorage.getItem("ROCP_idToken");
  if (isTokenExpired()) {
    renewAccessTokenCurrentUser();

    token = localStorage.getItem("ROCP_idToken");
  }

  return {
    method: "POST",
    headers: {
      "Content-Disposition": "attachment; filename=template.xlsx",
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

      Authorization: "Bearer " + token,
    },
    responseType: "blob",
    body: JSON.stringify(inObj),
  };
}

export function requestOptionsForPATCH(inObj) {
  let token = localStorage.getItem("ROCP_idToken");
  if (isTokenExpired()) {
    renewAccessTokenCurrentUser();

    token = localStorage.getItem("ROCP_idToken");
  }

  if (inObj === undefined || inObj === null) {
    return {
      method: "PATCH",

      headers: {
        "Content-type": "application/json",
        authorization: "Bearer " + token,
      },
    };
  } else {
    return {
      method: "PATCH",
      headers: {
        "Content-type": "application/json",
        authorization: "Bearer " + token,
      },

      body: JSON.stringify(inObj),
    };
  }
}

export function requestOptionsForDELETE() {
  let loggedinuser = localStorage.getItem("email");

  let token = localStorage.getItem("ROCP_idToken");
  if (isTokenExpired()) {
    renewAccessTokenCurrentUser();
    loggedinuser = localStorage.getItem("email");
    token = localStorage.getItem("ROCP_idToken");
  }
  if (loggedinuser === undefined || loggedinuser === null) return;
  return {
    method: "DELETE",

    headers: {
      "Content-type": "application/json",
      authorization: "Bearer " + token,
    },
  };
}

export const isTokenExpired = () => {
  let expDate = localStorage.getItem("expire_at");
  expDate = parseInt(expDate);
  var curDate = new Date();
  var seconds = Math.round(curDate.getTime() / 1000);
  if (isNaN(expDate) || expDate < seconds) {
    return true;
  }
  // Check if the token is expired by comparing with the current time
};
export const isTokenExpiredasync = async () => {
  let expDate = localStorage.getItem("expire_at");
  expDate = parseInt(expDate);
  var curDate = new Date();
  var seconds = Math.round(curDate.getTime() / 1000);
  if (isNaN(expDate) || expDate < seconds) {
    return true;
  }
  // Check if the token is expired by comparing with the current time
};
export function requestOptionsForRWAAPIGET() {
  return {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Ocp-Apim-Subscription-Key":
        process.env.REACT_APP_RWA_API_SUBSCRIPTIONKEY,
    },
  };
}
