import "./ApplicationHub.css";
import PropTypes from "prop-types";

import React, { useContext, useCallback, useEffect, useState } from "react";
function ApplicationHub({ items, ClickEvent }) {
  const [apps, setApps] = useState([]);

  useEffect(() => {
    if (items.length) {
      setApps(items);
    }
  }, [items]);

  // const performClick = (e, url) => {
  //   handleClick();
  // };

  return (
    <div className="application-container">
      <div className="application-bottom-row">
        <div class="application-bottom-row">
          {apps.map((item, index) => (
            <div class="application-box">
              <div className="application-icon-wrap">
                <a
                  style={{ textDecoration: "none" }}
                  // href={item.applicationurl}
                  href=""
                  onClick={ClickEvent(
                    item.applicationurl,
                    item.applicationname
                  )}
                >
                  <div class="application-result-div">
                    <div class="computing-cloud-m-48-coding">
                      <img
                        class="application-m-48-coding"
                        // width="128"
                        // height="128"
                        src={item.applicationicon}
                        alt={item.applicationname}
                      />
                    </div>
                  </div>
                </a>
              </div>
              <div className="app-feature-card">
                <div className="app-feature-card2">
                  <div className="app-content">
                    <div className="app-heading">
                      <div className="app-feature-title-style-as-headline-h-3-mixed-case-lorem-ipsum">
                        <a
                          style={{ textDecoration: "none", color: "inherit" }}
                          // href={item.applicationurl}
                          href=""
                          onClick={ClickEvent(
                            item.applicationurl,
                            item.applicationname
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          {item.applicationname}{" "}
                        </a>
                      </div>
                    </div>

                    <div className="app-description">
                      {item.applicationdescription}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
ApplicationHub.defaultProps = {
  items: [],
};

ApplicationHub.propTypes = {
  items: PropTypes.array,
};

export default React.memo(ApplicationHub);
