import "./styles.css";

export const PageFooter = ({ breakpoint = "desktop", ...props }) => {
  return (
    <div className="footer">
      <div className="page-footer">
        <div className="follow-links">
          <div className="subscribe">
            <div className="page-footer-frame-39">
              <svg
                className="communication-envelope-line"
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.375 20.625L30 33.75L50.625 20.625M9.375 13.125V46.875H50.625V13.125H9.375Z"
                  stroke="#76B900"
                  strokeWidth="2"
                />
              </svg>

              <div className="sign-up-for-nvidia-news">
                Sign Up for NVIDIA News{" "}
              </div>
            </div>
            <div className="page-footer-cta-button-primary">
              <div className="page-footer-button-text">
                <a
                  href="https://www.nvidia.com/en-us/preferences/email-signup/"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: "none",
                    color:
                      "var(--grayscale-black, var(--grayscale-black, #000))",
                  }}
                >
                  Subscribe
                </a>{" "}
              </div>
            </div>
          </div>
          <div className="social">
            <div className="follow-nvidia">Follow NVIDIA </div>
            <div className="social-icons">
              <a
                href="https://www.facebook.com/NVIDIA"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  className="icon-font-awesome-free-brands-f-facebook-f"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.6538 13.25L17.2093 9.63047H13.7362V7.28164C13.7362 6.29141 14.2214 5.32617 15.7769 5.32617H17.3558V2.24453C17.3558 2.24453 15.9229 2 14.553 2C11.6929 2 9.82334 3.73359 9.82334 6.87187V9.63047H6.64404V13.25H9.82334V22H13.7362V13.25H16.6538Z"
                    fill="#CCCCCC"
                  />
                </svg>
              </a>
              <a
                href="https://twitter.com/nvidia"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  className="icon-font-awesome-free-brands-t-twitter"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.9442 7.92639C19.9569 8.10405 19.9569 8.28175 19.9569 8.4594C19.9569 13.8782 15.8326 20.1219 8.29444 20.1219C5.97209 20.1219 3.81473 19.4493 2 18.2818C2.32996 18.3198 2.64719 18.3325 2.98985 18.3325C4.90607 18.3325 6.67006 17.6853 8.0787 16.5813C6.27666 16.5432 4.7665 15.363 4.24618 13.7386C4.50001 13.7766 4.7538 13.802 5.02032 13.802C5.38833 13.802 5.75638 13.7513 6.099 13.6625C4.22083 13.2817 2.81215 11.632 2.81215 9.6396V9.58886C3.35782 9.89343 3.99239 10.0838 4.66493 10.1091C3.56087 9.37308 2.83754 8.11675 2.83754 6.69541C2.83754 5.934 3.04055 5.23603 3.3959 4.62689C5.41369 7.1142 8.4467 8.73854 11.8477 8.91624C11.7843 8.61167 11.7462 8.29444 11.7462 7.97717C11.7462 5.71826 13.5736 3.87817 15.8452 3.87817C17.0254 3.87817 18.0914 4.3731 18.8401 5.17259C19.7665 4.99494 20.6548 4.65228 21.4416 4.18275C21.137 5.13455 20.4898 5.93404 19.6396 6.44162C20.4645 6.35283 21.264 6.12435 22 5.80713C21.4417 6.61928 20.7437 7.3426 19.9442 7.92639Z"
                    fill="#CCCCCC"
                  />
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/nvidia/"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  className="icon-font-awesome-free-brands-l-linkedin-in"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.47679 21.9998H2.33036V8.64712H6.47679V21.9998ZM4.40134 6.82569C3.07545 6.82569 2 5.72748 2 4.40158C2 3.76471 2.253 3.15392 2.70334 2.70358C3.15367 2.25324 3.76446 2.00024 4.40134 2.00024C5.03821 2.00024 5.649 2.25324 6.09934 2.70358C6.54968 3.15392 6.80268 3.76471 6.80268 4.40158C6.80268 5.72748 5.72679 6.82569 4.40134 6.82569ZM21.9955 21.9998H17.858V15.4998C17.858 13.9507 17.8268 11.9641 15.7022 11.9641C13.5464 11.9641 13.2161 13.6471 13.2161 15.3882V21.9998H9.07411V8.64712H13.0509V10.4685H13.1089C13.6625 9.41944 15.0147 8.3123 17.0321 8.3123C21.2286 8.3123 22 11.0757 22 14.665V21.9998H21.9955Z"
                    fill="#CCCCCC"
                  />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/nvidia/?hl=en"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  className="icon-font-awesome-free-brands-i-instagram"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0022 6.87336C9.16453 6.87336 6.87563 9.16226 6.87563 12C6.87563 14.8377 9.16453 17.1266 12.0022 17.1266C14.8399 17.1266 17.1288 14.8377 17.1288 12C17.1288 9.16226 14.8399 6.87336 12.0022 6.87336ZM12.0022 15.3329C10.1684 15.3329 8.66927 13.8382 8.66927 12C8.66927 10.1617 10.164 8.66701 12.0022 8.66701C13.8405 8.66701 15.3352 10.1617 15.3352 12C15.3352 13.8382 13.836 15.3329 12.0022 15.3329ZM18.5343 6.66366C18.5343 7.32847 17.9989 7.85942 17.3385 7.85942C16.6737 7.85942 16.1428 7.324 16.1428 6.66366C16.1428 6.00331 16.6782 5.4679 17.3385 5.4679C17.9989 5.4679 18.5343 6.00331 18.5343 6.66366ZM21.9297 7.87727C21.8539 6.27548 21.488 4.85663 20.3146 3.68764C19.1456 2.51865 17.7267 2.15278 16.1249 2.07247C14.4741 1.97877 9.52593 1.97877 7.87507 2.07247C6.27775 2.14832 4.8589 2.51419 3.68544 3.68318C2.51199 4.85217 2.15059 6.27102 2.07027 7.87281C1.97658 9.52367 1.97658 14.4718 2.07027 16.1227C2.14612 17.7245 2.51199 19.1433 3.68544 20.3123C4.8589 21.4813 6.27328 21.8472 7.87507 21.9275C9.52593 22.0212 14.4741 22.0212 16.1249 21.9275C17.7267 21.8516 19.1456 21.4857 20.3146 20.3123C21.4835 19.1433 21.8494 17.7245 21.9297 16.1227C22.0234 14.4718 22.0234 9.52813 21.9297 7.87727ZM19.797 17.894C19.449 18.7685 18.7752 19.4422 17.8963 19.7947C16.58 20.3168 13.4568 20.1963 12.0022 20.1963C10.5477 20.1963 7.41997 20.3123 6.1082 19.7947C5.23369 19.4467 4.55996 18.773 4.20747 17.894C3.68544 16.5778 3.80591 13.4545 3.80591 12C3.80591 10.5454 3.68991 7.4177 4.20747 6.10593C4.55549 5.23142 5.22922 4.55769 6.1082 4.20521C7.42443 3.68318 10.5477 3.80365 12.0022 3.80365C13.4568 3.80365 16.5845 3.68764 17.8963 4.20521C18.7708 4.55323 19.4445 5.22696 19.797 6.10593C20.319 7.42216 20.1985 10.5454 20.1985 12C20.1985 13.4545 20.319 16.5822 19.797 17.894Z"
                    fill="#CCCCCC"
                  />
                </svg>
              </a>
              <a
                href="https://www.youtube.com/user/nvidia"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  className="icon-font-awesome-free-brands-y-youtube"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.5821 7.16905C21.3521 6.30296 20.6744 5.62086 19.8139 5.38938C18.2542 4.96875 12 4.96875 12 4.96875C12 4.96875 5.74586 4.96875 4.18613 5.38938C3.32565 5.6209 2.64794 6.30296 2.41792 7.16905C2 8.73889 2 12.0142 2 12.0142C2 12.0142 2 15.2895 2.41792 16.8593C2.64794 17.7254 3.32565 18.3791 4.18613 18.6106C5.74586 19.0312 12 19.0312 12 19.0312C12 19.0312 18.2541 19.0312 19.8139 18.6106C20.6744 18.3791 21.3521 17.7254 21.5821 16.8593C22 15.2895 22 12.0142 22 12.0142C22 12.0142 22 8.73889 21.5821 7.16905ZM9.95453 14.9879V9.04046L15.1818 12.0143L9.95453 14.9879Z"
                    fill="#CCCCCC"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageFooter;
