import { useContext, useEffect, useState } from "react";
import Spinner from "@kaizen-ui/spinner";
import {
  fetchRole,
  postRole,
  patchRole,
  deleteRole,
  fetchPermissionView,
} from "../../../api";
import { GetSelectedPermissions } from "../../../common/utils";
import { Layout } from "../../../layout";
import { useParams } from "react-router-dom";
import { Field, Formik } from "formik";
import Block from "@kaizen-ui/block";
import Button from "@kaizen-ui/button";
import Textbox from "@kaizen-ui/textbox";
import * as React from "react";
import "./styles.css";
import { BlockSection } from "@kaizen-ui/block";
import ButtonGroup from "@kaizen-ui/button";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { string, object } from "yup";
import "./styles.css";
import { Banner, KaizenThemeContext } from "@kaizen-ui/complete";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PermissionTable from "../../../components/permissiontable";
import AccessibilityContext from "../../../components/auth/UserPermissionContext";
import WithAuth from "../../../components/auth/WithAuth";
import WithAdminUIAuth from "../../../components/auth/WithAdminUIAuth";
const Loading = () => (
  <Layout title="Loading...">
    <Spinner className="loading-spinner" size="small" />
  </Layout>
);
const initstate = {
  roleid: 0,
  description: "",
  rolename: "",
};
const validationSchema = object().shape({
  rolename: string().required("Role Name is required."),
  description: string().required("Role Description is required."),
});

function RoleDetail() {
  let { id } = useParams();
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [errormsg, setErrorMsg] = useState("");
  const [initialValues] = useState(initstate);
  const [success, setSuccess] = useState("");
  const { colors } = useContext(KaizenThemeContext);

  //accessebility

  const permissions = useContext(AccessibilityContext).filter(
    (x) => x.pagename === "role"
  )[0];

  const [expanded, setExpanded] = React.useState(false);

  // const [items, setItems] = useState(initialItems);
  const [items, setItems] = useState([]);
  // const [acptPermissions, setacptPermissions] = useState([]);
  // const [selectedPermissions, setselectedPermissions] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Event handler for the checkbox's onChange event
  const handleCheckboxChange = (index) => (e) => {
    const options = [...items];

    let val = e.target.checked;
    let rowid = e.target.name;
    let permissionId = e.target.value;

    let permissiontype = rowid.split("#")[0];
    let itemname = rowid.split("#")[1];

    if (e.target.id.includes("read")) {
      //Find index of specific object using findIndex method.

      let objIndex = options.findIndex(
        (obj) =>
          obj.read == permissionId &&
          obj.permissiontype === permissiontype &&
          obj.name === itemname
      );

      //Update object's name property.
      if (objIndex > -1) {
        options[objIndex].readChecked = val;
      }
    } else if (e.target.id.includes("create")) {
      //Find index of specific object using findIndex method.
      let objIndex = options.findIndex(
        (obj) =>
          obj.create == permissionId &&
          obj.permissiontype === permissiontype &&
          obj.name === itemname
      );

      //Update object's name property.
      if (objIndex > -1) {
        options[objIndex].createChecked = val;
      }
    } else if (e.target.id.includes("delete")) {
      //Find index of specific object using findIndex method.
      let objIndex = options.findIndex(
        (obj) =>
          obj.delete == permissionId &&
          obj.permissiontype === permissiontype &&
          obj.name === itemname
      );

      //Update object's name property.
      if (objIndex > -1) {
        options[objIndex].deleteChecked = val;
      }
    } else if (e.target.id.includes("update")) {
      //Find index of specific object using findIndex method.
      let objIndex = options.findIndex(
        (obj) =>
          obj.update == permissionId &&
          obj.permissiontype === permissiontype &&
          obj.name === itemname
      );

      //Update object's name property.
      if (objIndex > -1) {
        options[objIndex].updateChecked = val;
      }
    }
    setData(options);
    // setselectedPermissions(options);
  };

  function errorHandler(error, setError, setErrorMsg) {
    setErrorMsg(error.status_message);
    setError(error);
    setTimeout(() => {
      setError(undefined);
    }, 5000);
  }

  function SuccessHandler(setSuccess, setData, data, navigate) {
    setTimeout(() => {
      setSuccess("");
      if (data !== undefined) {
        setData(data);
        navigate("/role");
        //navigate("/role/" + data.roleid);
        //id = data.roleid.toString();
      } else {
        navigate("/role");
      }
    }, 2000);
  }

  const disableDelete = () => {
    if (data && permissions?.delete) {
      if (data.roleid > 0) return false;
    }
    return true;
  };

  const disableEdit = () => {
    return !(permissions?.create || (permissions?.update && id > 0));
  };

  const deleteRoleName = () => {
    if (id !== undefined) {
      var bconfirm = window.confirm("Do you want to delete the Role?");
      if (bconfirm) {
        deleteRole(parseInt(id))
          .then((data) => {
            setSuccess("Successfully deleted role - " + id);
            SuccessHandler(setSuccess, undefined, undefined, navigate);
          })
          .catch((error) => {
            errorHandler(error, setError, setErrorMsg);
          })
          .finally(() => {});
      }
    }
  };

  useEffect(() => {
    if (id === "0") {
      setData({
        roleid: 0,
        description: "",
        rolename: "",
      });

      fetchPermissionView(Number(id)).then((data) => {
        setItems(data);
      });
    } else {
      fetchPermissionView(Number(id)).then((data) => {
        setItems(data);
      });
      fetchRole(Number(id)).then((data) => {
        setData(data);
      });
    }
  }, [id]);

  const navigate = useNavigate();
  if (!data) return <Loading />;
  if (permissions != null && permissions !== undefined && !permissions.read) {
    navigate("/unauthorized");
  }

  if (permissions?.read) {
    return (
      <Formik
        initialValues={data !== null ? data : initialValues}
        initialTouched={{
          description: true,
          roleid: true,
          rolename: true,
        }}
        onReset={(values) => {
          values = data;
        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          // buildrequest(values)
          if (id === "0") {
            const createdata = values;
            createdata.permissions = GetSelectedPermissions(items);

            setError(undefined);
            postRole(createdata)
              .then((data) => {
                setSuccess("Successfully created role - " + data.rolename);
                SuccessHandler(setSuccess, setData, data, navigate);
              })
              .catch((error) => {
                errorHandler(error, setError, setErrorMsg);
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          } else {
            const updatedata = values;
            updatedata.permissions = GetSelectedPermissions(items);
            patchRole(updatedata)
              .then((data) => {
                setSuccess("Successfully updated role - " + data.rolename);
                SuccessHandler(setSuccess, setData, data, navigate);
              })
              .catch((error) => {
                errorHandler(error, setError, setErrorMsg);
              })
              .finally(() => {
                actions.resetForm();
                actions.setSubmitting(false);
              });
          }
        }}
        validationSchema={validationSchema}
        validateOnMount
      >
        {(formikProps) => (
          <Layout
            title="Role"
            headerChildren={
              <ButtonGroup>
                <Button
                  icon={{ name: "PlaybackCircleRewind", variant: "solid" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Go Back
                </Button>
              </ButtonGroup>
            }
          >
            {error && error !== undefined ? (
              // <Result status="error" title="Error" subTitle={JSON.stringify(errormsg)}  />
              <Banner
                className="bannertofront"
                rounded
                status="critical"
                title="Error"
              >
                {JSON.stringify(errormsg)}
              </Banner>
            ) : (
              ""
            )}
            {success !== "" ? (
              <Banner
                className="bannertofront"
                rounded
                status="success"
                title="Success"
              >
                {success}
              </Banner>
            ) : (
              //<Result status="success" title={success} />
              ""
            )}
            <Block
              inline
              className="form-example"
              loading={formikProps.isSubmitting}
            >
              <BlockSection size="full" className="hubtagfieldblocksection">
                <h4
                  className="hubtagfieldh4"
                  style={{ color: colors.fontColor }}
                >
                  Role Name<span className="hubtagfieldspan">*</span>
                </h4>

                <Field
                  as={Textbox}
                  className="zero-left zero-right hubtagfieldtext"
                  disabled={disableEdit()}
                  name="rolename"
                  values={formikProps.values.rolename}
                  placeholder="please enter role name"
                  pattern={formikProps.errors.rolename}
                  validationMessage={formikProps.errors.rolename}
                  required={true}
                  valid={true}
                  showValidIcon={true}
                />
              </BlockSection>
              <BlockSection size="full" className="hubtagfieldblocksection">
                <h4
                  className="hubtagfieldh4"
                  style={{ color: colors.fontColor }}
                >
                  Description<span className="hubtagfieldspan">*</span>
                </h4>

                <Field
                  as={Textbox}
                  className="zero-left zero-right hubtagfieldtext"
                  disabled={disableEdit()}
                  name="description"
                  values={formikProps.values.description}
                  placeholder="please enter description"
                  pattern={formikProps.errors.description}
                  validationMessage={formikProps.errors.description}
                  required={true}
                  valid={true}
                  showValidIcon={true}
                />
              </BlockSection>
              <BlockSection size="full" className="hubtagfieldblocksection">
                <h4
                  style={{
                    color: "#fff",
                    height: "40px",
                    padding: "10px",
                    textAlign: "center",
                    fontSize: "15px",
                    width: "99%",
                    justifyContent: "center",
                    backgroundColor: "rgb(112,181,0)",
                  }}
                >
                  Role Permission Selector
                </h4>
                <div style={{ width: "99%", justifyContent: "center" }}>
                  <Accordion
                    disabled={disableEdit()}
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        Application
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <PermissionTable
                          items={items.filter(
                            (x) => x.permissiontype === "application"
                          )}
                          handleCheckboxChange={handleCheckboxChange}
                          //onChange={onChange}
                        ></PermissionTable>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    disabled={disableEdit()}
                    defaultExpanded={true}
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        API
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <PermissionTable
                          items={items.filter(
                            (x) => x.permissiontype === "api"
                          )}
                          handleCheckboxChange={handleCheckboxChange}
                        ></PermissionTable>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                    disabled={disableEdit()}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3bh-content"
                      id="panel3bh-header"
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        UI
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <PermissionTable
                          items={items.filter((x) => x.permissiontype === "ui")}
                          handleCheckboxChange={handleCheckboxChange}
                        ></PermissionTable>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </BlockSection>
              <BlockSection size="full">
                <Button
                  className="formsubmitbtn"
                  type="critical"
                  onClick={() => navigate("/role")}
                >
                  Cancel
                </Button>

                <Button
                  className="formsubmitbtn"
                  disabled={disableEdit()}
                  onClick={() => {
                    formikProps.isSubmitting = true;
                    formikProps.handleSubmit();
                  }}
                >
                  Submit
                </Button>
              </BlockSection>
              <BlockSection size="full">
                {permissions?.delete ? (
                  <Button
                    className="formsubmitbtn"
                    type="critical"
                    disabled={disableDelete()}
                    onClick={(e) => deleteRoleName()}
                  >
                    Delete Role
                  </Button>
                ) : (
                  <span></span>
                )}
              </BlockSection>
            </Block>
          </Layout>
        )}
      </Formik>
    );
  }
}

export default WithAuth(WithAdminUIAuth(RoleDetail));
