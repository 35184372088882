import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
// import GlobalFooter from "../../components/globalfooter";
// import Header from "../../components/header";
import "./styles.css";

function SecureLogin() {
  const navigate = useNavigate();
  const [originState, setOriginState] = useState(() => {
    const storedData = localStorage.getItem("originstate");
    return storedData
      ? storedData
      : new URLSearchParams(window.location.href.split(/(\?|#)/)[2]).get(
          "state"
        );
  });

  const [originUrl, setOriginUrl] = useState(() => {
    const storedData = localStorage.getItem("originurl");
    return storedData
      ? storedData
      : new URLSearchParams(window.location.href.split(/(\?|#)/)[2]).get(
          "redirect_uri"
        );
  });

  useEffect(() => {
    const IsUserLoggedIn = localStorage.getItem("IsUserLoggedIn");
    const email = localStorage.getItem("email");
    let token = localStorage.getItem("ROCP_idToken");
    if (
      (IsUserLoggedIn ||
        IsUserLoggedIn === "true" ||
        IsUserLoggedIn === "inprogress") &&
      email !== undefined &&
      email !== "" &&
      email !== null &&
      token !== "" &&
      token !== undefined &&
      token !== null
    ) {
      const urlParams = new URLSearchParams(
        window.location.href.split(/(\?|#)/)[2]
      );
      if (urlParams && urlParams.size > 0) {
        let secureform_formid = document.getElementById("secureform_form");
        secureform_formid.submit();
        localStorage.removeItem("originurl");
        localStorage.removeItem("originstate");
        localStorage.removeItem("originredirecturi");
      } else {
        let secureform_formid = document.getElementById("secureform_form");
        secureform_formid.submit();
        localStorage.removeItem("originurl");
        localStorage.removeItem("originstate");
        localStorage.removeItem("originredirecturi");
      }
    } else {
      const urlParams = new URLSearchParams(
        window.location.href.split(/(\?|#)/)[2]
      );
      if (urlParams && urlParams.size > 0) {
        //clear all local storeage
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem("originurl", urlParams.get("redirect_uri"));
        localStorage.setItem("originstate", urlParams.get("state"));
        localStorage.setItem(
          "originredirecturi",
          window.location.origin + window.location.pathname
        );
      }

      navigate("/login");
    }
  }, []);

  return (
    <div>
      {/* <Header
        headerHeading="NVIDIA Application Hub Login"
        loginHeader={true}
        dashboardHeader={false}
      ></Header> */}

      {/* <div>
        {" "}
        <div class="email-picker-container">
          <div class="email-picker-center-panel">
            <div class="email-picker-image"></div>
            <div class="email-picker-panel">
              <div class="email-input-text">
                Sign&nbsp;in or sign&nbsp;up with your email address
              </div>
              <div class="email-input"> */}

      <form action={originUrl} id="secureform_form" method="POST">
        <div className="hide-form">
          <input
            id="email"
            name="email"
            type="text"
            value={localStorage.getItem("email")}
          />
          <input id="state" name="state" type="text" value={originState} />
        </div>

        {/* <div class="email-input-button-container">
                    <button
                      class="button-cta bright"
                      id="submitform"
                      type="submit"
                    >
                      Continue
                    </button>
                  </div> */}
      </form>
      {/* <br></br>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <GlobalFooter></GlobalFooter> */}
    </div>
  );
}

export default SecureLogin;
