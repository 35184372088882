import React from "react";

import PropTypes from "prop-types";
import "./styles.css";
function RoleSelectorTable({ items, toggleCheckbox, disable }) {
  return (
    <div className="rolewrapper">
      {items.map((role, index) => {
        return (
          <div key={role.roleid} sm={3}>
            <input
              type="checkbox"
              value={role.rolename}
              checked={role.isChecked}
              id={role.roleid}
              onChange={toggleCheckbox(index)}
              name={role.roleid + "-" + role.rolename}
              class="checkbox-radio"
              title={role.description}
              disabled={disable}
            ></input>
            <label
              for={role.roleid}
              title={role.description}
              class="square-radio-label"
            >
              {role.rolename}
            </label>
          </div>
        );
      })}
    </div>
  );
}

RoleSelectorTable.defaultProps = {
  items: [],
  toggleCheckbox: () => {},
  disable: false,
};

RoleSelectorTable.propTypes = {
  items: PropTypes.array,
  toggleCheckbox: PropTypes.func,
  disable: PropTypes.bool,
};

export default React.memo(RoleSelectorTable);
