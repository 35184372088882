import { React } from "react";
import { useState } from "react";
import { Layout } from "../../layout";
import Button, { ButtonGroup } from "@kaizen-ui/button";
import WithAuth from "../../components/auth/WithAuth";
import WithAdminUIAuth from "../../components/auth/WithAdminUIAuth";
import { Table, TableRow, TableHeader, TableDataCell } from "@kui-react/table";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Form from "react-bootstrap/Form";
import axios, { Axios } from "axios";
import { Message } from "@mui/icons-material";

// import Button from "@mui/material/Button";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};



const baseUrl = process.env.REACT_APP_APPHUB_API_ENDPOINT;
const BulkRoleUpdate = () => {
  const [open, setOpen] = useState(false);
  const [openValidaion, setValidationModelOpen] = useState(false);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [fileUploadType, setFileUploadType] = useState();
  const [fileEntityType, setEntityType] = useState();
  const [actionType, setActionType] = useState();
  const [sheetNoVisibility, setSheetNoVisibility] = useState("hidden");
  const [selectedSheetNo, setExcelSheetNo] = useState("");
  const [show, setShow] = useState(false);
  const [rowsData, setRowsData] = useState([,]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const saveFile = (e) => {
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
    if(e.target.files[0].size!=0)
    {
    setFileName(e.target.files[0].name);
    }
  };

  const handleFileTypeChange = (e) => {
    setFileUploadType(e.target.value);
    setSheetNoVisibility(e.target.value == 1 ? "visible" : "hidden");
  };

  const handleEntityTypeChange = (e) => {
    setEntityType(e.target.value);
  };

  const handleActionTypeChange = (e) => {
    setActionType(e.target.value);
  };

  const handleExcelSheet = (e) => {
    setExcelSheetNo(e.target.value);
  };

  const handleValidationOpen = () => {
    setValidationModelOpen(true);
  };
  const handleValidationClose = () => {
    setValidationModelOpen(false);
  };
  




  const handleUploading = (param) => {
    setShow(false);
if(fileName==undefined || fileName==null)
{
  alert("Please select file to upload");
  return;
}
  if(file==undefined || file.length==0)
  {
  alert("Please select file to upload");
  return;
  }
  

    const formData = new FormData();
    formData.append("formFile", file);
    formData.append("fileName", fileName);
    formData.append("Filetype", fileUploadType);
    formData.append("ActionType", 1);
    formData.append("EntityType", 1);
    formData.append("SheetNo", selectedSheetNo);

    try {
      try {
        var token = localStorage.getItem("ROCP_idToken");
        const headers = {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
          "Accept": "*/*"
        };
        const selectionInput = [,];
        axios.post(baseUrl+"UsersUpload/SaveRoles", formData, {
          headers:headers,
          })
          .then((response) => {
            setRowsData([]);
            response.data.map(
              (item) =>
              { 
                selectionInput.push(item);
              });
              setRowsData(selectionInput);
              setFile("");
              setFileName("");
            handleClose();
          })
          .catch((error) => {
            console.error("There was an error!", error);
            alert("User roles updation failed. Please check the log.");
            return;
          });
      } catch (ex) {
        console.log(ex);
        alert("User roles updation failed. Please check the log.");
        return;
      }
    } catch (ex) {
      console.log("failed" + ex);
      alert("User roles updation failed. Please check the log.");
      return;
    }
  };

  return (
    <Layout
      key="laycustomresponse"
      title="User Migration"
      // headerChildren={
      //   <ButtonGroup key="btngrpcreateresponse">
      //     <Button
      //       key="btncreateresponse"
      //       icon={{ name: "ActionsCircleAdd", variant: "solid" }}
      //       // onClick={() => {
      //       //   navigate("/application/0");
      //       // }}
      //     >
      //       User Migration
      //     </Button>
      //   </ButtonGroup>
      // }
      headerChildren={
        <ButtonGroup key="btngrpcreateresponse">
          {/* <Button
            key="btncreateresponse"
            icon={{ name: "ActionsCircleAdd", variant: "solid" }}
             onClick={() => { exportTemplate(); }}
          >   Download Template
          </Button> */}
          <Button
            key="btncreateresponse1"
            icon={{ name: "ActionsCircleAdd", variant: "solid" }}
            onClick={() => {handleValidationOpen();}}
          >
            Read Validations
          </Button>
         
        </ButtonGroup>
        
      }
    >
      <div>
        <div>
          <Button onClick={handleOpen}>Upload User Roles</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, width: 400 }}>
              <Form>

              {/* <Form.Group controlId="entityTypeSelection">
                  <Form.Control
                    as="select"
                    onChange={handleEntityTypeChange}
                    selected={1}
                  >
                    <option value="NotSelected">Select Entity Type</option>
                    <option value="1">User</option>
                    <option value="2">User Role</option>
                  </Form.Control>
                </Form.Group>
                <br />
                <Form.Group controlId="fileTypeSelect">
                  <Form.Control
                    as="select"
                    onChange={handleActionTypeChange}
                    selected={1}
                  >
                    <option value="NotSelected">Select Action Type</option>
                    <option value="1">New Users</option>
                    <option value="2">Update Existing Users</option>
                  </Form.Control>
                </Form.Group> */}
            
                <br />
                <Form.Group controlId="fileTypeSelect">
                  <Form.Control
                    as="select"
                    onChange={handleFileTypeChange}
                    selected={1}
                  >
                    <option value="NotSelected">Select Upload File Type</option>
                    <option value="1">Excel</option>
                    <option value="2">CSV</option>
                  </Form.Control>
                </Form.Group>
                <br />
                {/* style={{visibility: sheetNoVisibility}} */}
                <Form.Group controlId="sheetNoSelection" >
                  <Form.Control as="select" onChange={handleExcelSheet} style={{visibility: sheetNoVisibility}}>
                    <option value="Select Sheet No">Select Sheet No </option>
                    <option value="Sheet1">Sheet1</option>
                    <option value="Sheet2">Sheet2</option>
                  </Form.Control>
                </Form.Group>

                <br />
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control type="file" onChange={saveFile} />
                </Form.Group>
              </Form>
              <br/>
              <Button variant="primary" onClick={handleUploading}>
                Process Uploading
              </Button>
            </Box>
          </Modal>
          <Modal open={openValidaion} onClose={handleValidationClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <Box sx={{ ...style, width: 600 }}>
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <h4>Please read below validations while doing bulk role update.</h4>
                  <ol>
                  <li>The Excel sheet must include both the Mail and Roles columns.</li>
                  <li>Ensure email addresses are in plain text rather than hyperlinks.</li>
                  <li>Email ID matching is not validated in AppHub.</li>
                  <li>The Roles column should only contain role names to be added for each user.</li>
                  <li>Roles must be separated by delimiters such as commas (,) or pipes (|).</li>
                  </ol>
                </Form.Group>
              </Form>
             
            </Box>
          </Modal>
        </div>
        <div className="container">
          <div className="table-responsive" id="roletable">
            <div className="row">
              <div className="col-sm-8">
                <Table width="100%">
                  <TableRow>
                   <TableHeader> Email </TableHeader>
                    <TableHeader> Role </TableHeader>
                    <TableHeader> Message </TableHeader>
                    <TableHeader> Status </TableHeader>
                  </TableRow>
                 
                    {rowsData?.map((columns) => {
                      return (
                        <TableRow>

                          <TableDataCell>{columns.email}</TableDataCell>
                          <TableDataCell>{columns.name}</TableDataCell>
                          <TableDataCell>{columns.message}</TableDataCell>
                          <TableDataCell>{columns.statusMessage}</TableDataCell>
                        
                          </TableRow>
                      );
                    })}
                  
                </Table>
              </div>


              <div className="col-sm-4"></div>
            </div>
          </div>

        </div>
      </div>
    </Layout>
  );
};

export default WithAuth(WithAdminUIAuth(BulkRoleUpdate));
