import { useContext, useEffect, useState } from "react";
import * as React from "react";
import Spinner from "@kaizen-ui/spinner";
import {
  fetchPermission,
  postPermission,
  patchPermission,
  deletePermission,
} from "../../../api";
import { Layout } from "../../../layout";
import { useParams } from "react-router-dom";
import { ErrorMessage, Field, Formik } from "formik";
import Block from "@kaizen-ui/block";
import Button from "@kaizen-ui/button";
import Textbox from "@kaizen-ui/textbox";
import Select from "@kaizen-ui/select";

import "./styles.css";
import { BlockSection } from "@kaizen-ui/block";
import ButtonGroup from "@kaizen-ui/button";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { string, object } from "yup";
import "./styles.css";
import { Banner, KaizenThemeContext } from "@kaizen-ui/complete";
import { PERMISSIONTYPES, ACCESSTYPES } from "../../../common/constants";
import AccessibilityContext from "../../../components/auth/UserPermissionContext";
import WithAuth from "../../../components/auth/WithAuth";
import WithAdminUIAuth from "../../../components/auth/WithAdminUIAuth";
const Loading = () => (
  <Layout title="Loading...">
    <Spinner className="loading-spinner" size="small" />
  </Layout>
);
const initstate = {
  permissionid: 0,
  description: "",
  permissiontype: "application",
  accesstype: "read",
};
const validationSchema = object().shape({
  description: string().required("permission name is required."),
  // permissiontype: array(object({
  //    })).min(1,'Atleast one permission type is required.'),

  //    accesstype: array(object({
  //   })).min(1,'Atleast one access type is required.'),
});

const PermissionDetail = () => {
  let { id } = useParams();
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [errormsg, setErrorMsg] = useState("");
  const [initialValues] = useState(initstate);
  const [success, setSuccess] = useState("");
  const { colors } = useContext(KaizenThemeContext);

  const permissions = useContext(AccessibilityContext).filter(
    (x) => x.pagename === "permission"
  )[0];

  function errorHandler(error, setError, setErrorMsg) {
    setErrorMsg(error.status_message);
    setError(error);
    setTimeout(() => {
      setError(undefined);
    }, 5000);
  }
  function SuccessHandler(setSuccess, setData, data, navigate) {
    setTimeout(() => {
      setSuccess("");
      if (data !== undefined) {
        setData(data);
        navigate("/permission");
        // navigate("/permission/" + data.permissionId);
        //  id = data.permissionId.toString();
      } else {
        navigate("/permission");
      }
    }, 2000);
  }

  const disableDelete = () => {
    if (data && permissions?.delete) {
      if (data.permissionid > 0) return false;
    }
    return true;
  };

  const disableEdit = () => {
    return !(permissions?.create || (permissions?.update && id > 0));
  };
  const deleteCustomResponse = () => {
    if (id !== undefined) {
      var bconfirm = window.confirm("Do you want to delete the Permission?");
      if (bconfirm) {
        deletePermission(parseInt(id))
          .then((data) => {
            setSuccess("Successfully deleted Permission - " + id);
            SuccessHandler(setSuccess, undefined, undefined, navigate);
          })
          .catch((error) => {
            errorHandler(error, setError, setErrorMsg);
          })
          .finally(() => {});
      }
    }
  };

  useEffect(() => {
    if (id === "0") {
      setData({
        permissionid: 0,
        description: "",
        permissiontype: PERMISSIONTYPES[0],
        accesstype: ACCESSTYPES[0],
      });
    } else {
      fetchPermission(Number(id)).then((data) => {
        let formdata = { ...data };
        formdata["permissiontype"] = PERMISSIONTYPES.find((permissiontype) => {
          return permissiontype.value === formdata.permissiontype;
        });
        formdata["accesstype"] = ACCESSTYPES.find((accesstype) => {
          return accesstype.value === formdata.accesstype;
        });

        setData(formdata);
      });
    }
  }, [id]);
  const navigate = useNavigate();
  if (!data) return <Loading />;
  if (permissions != null && permissions !== undefined && !permissions.read) {
    navigate("/unauthorized");
  }

  if (permissions?.read) {
    return (
      <Formik
        initialValues={data !== null ? data : initialValues}
        initialTouched={{
          description: true,
          permissiontype: true,
          accesstype: true,
        }}
        onReset={(values) => {
          values = data;
        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          // buildrequest(values)
          if (id === "0") {
            const createdata = values;
            createdata.accesstype = values.accesstype.value;
            createdata.permissiontype = values.permissiontype.value;
            setError(undefined);
            postPermission(createdata)
              .then((data) => {
                setSuccess(
                  "Successfully created Permission - " + data.description
                );
                SuccessHandler(setSuccess, setData, data, navigate);
              })
              .catch((error) => {
                errorHandler(error, setError, setErrorMsg);
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          } else {
            const updatedata = values;
            updatedata.accesstype = values.accesstype.value;
            updatedata.permissiontype = values.permissiontype.value;
            patchPermission(updatedata)
              .then((data) => {
                setSuccess(
                  "Successfully updated Permission - " + data.description
                );
                SuccessHandler(setSuccess, setData, data, navigate);
              })
              .catch((error) => {
                errorHandler(error, setError, setErrorMsg);
              })
              .finally(() => {
                actions.resetForm();
                actions.setSubmitting(false);
              });
          }
        }}
        validationSchema={validationSchema}
        validateOnMount
      >
        {(formikProps, handleChange) => (
          <Layout
            title="Permission"
            headerChildren={
              <ButtonGroup>
                <Button
                  icon={{ name: "PlaybackCircleRewind", variant: "solid" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Go Back
                </Button>
              </ButtonGroup>
            }
          >
            {error && error !== undefined ? (
              // <Result status="error" title="Error" subTitle={JSON.stringify(errormsg)}  />
              <Banner
                className="bannertofront"
                rounded
                status="critical"
                title="Error"
              >
                {JSON.stringify(errormsg)}
              </Banner>
            ) : (
              ""
            )}
            {success !== "" ? (
              <Banner
                className="bannertofront"
                rounded
                status="success"
                title="Success"
              >
                {success}
              </Banner>
            ) : (
              //<Result status="success" title={success} />
              ""
            )}
            <Block
              inline
              className="form-example"
              loading={formikProps.isSubmitting}
            >
              <BlockSection size="full" className="hubtagfieldblocksection">
                <h4
                  className="hubtagfieldh4"
                  style={{ color: colors.fontColor }}
                >
                  Permission Type<span className="hubtagfieldspan">*</span>
                </h4>
                <Select
                  errorText="please select permission type"
                  helperText="please select permission type"
                  className="zero-left zero-right hubtagfieldtext"
                  disabled={disableEdit()}
                  name="permissiontype"
                  placeholder="please select permission type"
                  pattern={formikProps.errors.permissiontype}
                  validationMessage={formikProps.errors.permissiontype}
                  required={true}
                  valid={true}
                  renderIcon={function ou() {}}
                  renderItem={function ou() {}}
                  size="standard"
                  value={formikProps.values.permissiontype}
                  onOpen={function ou() {}}
                  onChange={(event) => {
                    formikProps.setFieldValue("permissiontype", event);
                  }}
                  options={PERMISSIONTYPES}
                />

                {typeof formikProps.errors.permissiontype === "string" ? (
                  <ErrorMessage
                    name="permissiontype"
                    component="div"
                    className="error"
                  />
                ) : null}
              </BlockSection>

              <BlockSection size="full" className="hubtagfieldblocksection">
                <h4
                  className="hubtagfieldh4"
                  style={{ color: colors.fontColor }}
                >
                  Permission Name<span className="hubtagfieldspan">*</span>
                </h4>
                <Field
                  as={Textbox}
                  className="zero-left zero-right hubtagfieldtext"
                  disabled={disableEdit()}
                  name="description"
                  values={formikProps.values.description}
                  placeholder="please enter permission name"
                  pattern={formikProps.errors.description}
                  validationMessage={formikProps.errors.description}
                  required={true}
                  valid={true}
                  showValidIcon={true}
                />
              </BlockSection>

              <BlockSection size="full" className="hubtagfieldblocksection">
                <h4
                  className="hubtagfieldh4"
                  style={{ color: colors.fontColor }}
                >
                  Access Type<span className="hubtagfieldspan">*</span>
                </h4>
                <Select
                  errorText="please select access type"
                  helperText="please select access type"
                  className="zero-left zero-right hubtagfieldtext"
                  disabled={disableEdit()}
                  name="accesstype"
                  placeholder="please select access type"
                  pattern={formikProps.errors.accesstype}
                  validationMessage={formikProps.errors.accesstype}
                  required={true}
                  valid={true}
                  onChange={(event) => {
                    formikProps.setFieldValue("accesstype", event);
                  }}
                  options={ACCESSTYPES}
                  value={formikProps.values.accesstype}
                  renderIcon={function ou() {}}
                  renderItem={function ou() {}}
                  size="standard"
                />

                {typeof formikProps.errors.accesstype === "string" ? (
                  <ErrorMessage
                    name="accesstype"
                    component="div"
                    className="error"
                  />
                ) : null}
              </BlockSection>

              <BlockSection size="full">
                <Button
                  className="formsubmitbtn"
                  type="critical"
                  onClick={() => navigate("/permission")}
                >
                  Cancel
                </Button>

                <Button
                  className="formsubmitbtn"
                  disabled={disableEdit()}
                  onClick={() => {
                    formikProps.isSubmitting = true;
                    formikProps.handleSubmit();
                  }}
                >
                  Submit
                </Button>
              </BlockSection>
              <BlockSection size="full">
                {permissions?.delete ? (
                  <Button
                    className="formsubmitbtn"
                    type="critical"
                    disabled={disableDelete()}
                    onClick={(e) => deleteCustomResponse()}
                  >
                    Delete Permission
                  </Button>
                ) : (
                  <span></span>
                )}
              </BlockSection>
            </Block>
          </Layout>
        )}
      </Formik>
    );
  }
};

export default WithAuth(PermissionDetail);
