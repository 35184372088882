import Button from "@kaizen-ui/button";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";

export default function InternalServerError() {
  const navigate = useNavigate();
  return (
    <div className="unauthorized-page-container">
      <div className="unauthorized-page-content">
        <div
          style={{
            dispplay: "flex",
            justifyContent: "space-around",
            flexDirection: "row",
            marginBottom: "20px",
          }}
        >
          <p style={{ fontSize: "40px", margin: "0px", fontWeight: "bold" }}>
            <span style={{ fontSize: "40px", color: "red" }}>500</span> Internal
            Server Error
          </p>
          <p style={{ marginTop: "0px" }}>
            Something went wrong, Please try after some time !
          </p>
        </div>
        s
        <Button
          key="btncreateresponse"
          style={{ width: "200px" }}
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          Go to DashBoard
        </Button>
      </div>
    </div>
  );
}
