import { Button } from "@mui/material";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import { Box } from "@mui/system";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { exportToExcel } from "../../api";
import { saveAs } from "file-saver";
function ExcelExport({ filtering }) {
  const [isLoadingExcelData, setIsLoadingExcelData] = useState(false);

  const handleExportData = async () => {
    setIsLoadingExcelData(true);

    let response = await exportToExcel(filtering);

    // Example usage
    const fileName = `User_${getCurrentDateTime()}.xlsx`;

    if (response.status !== "error") {
      const blob = await response.blob();
      saveAs(blob, fileName);
    } else {
      toast.error("Unable to export data.");
    }

    setIsLoadingExcelData(false);
  };
  const getCurrentDateTime = () => {
    const currentDateTime = new Date();

    const year = currentDateTime.getFullYear();
    const month = (currentDateTime.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = currentDateTime.getDate().toString().padStart(2, "0");
    const hours = currentDateTime.getHours().toString().padStart(2, "0");
    const minutes = currentDateTime.getMinutes().toString().padStart(2, "0");
    const seconds = currentDateTime.getSeconds().toString().padStart(2, "0");

    const formattedDateTime = `${year}${month}${day}_${hours}${minutes}${seconds}`;

    return formattedDateTime;
  };

  return (
    <Box>
      <Button
        sx={{
          color: "#ffff",
          height: "33px",
          backgroundColor: "#76b900",
          width: "200px",
        }}
        className="sc-eqUAAy grpoRq kaizen-button rectangle regular primary solid"
        size="small"
        variant="filled"
        onClick={isLoadingExcelData ? null : handleExportData}
      >
        <DownloadForOfflineRoundedIcon />
        {isLoadingExcelData ? "Loading..." : " Export to Excel"}
      </Button>
    </Box>
  );
}

export default ExcelExport;
