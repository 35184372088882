import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Accordion.css";

export default function NeedHelpAccordion() {
  // Define a CSS class for the Accordion
  const customAccordionStyle = {
    border: "none",
    boxShadow: "none",
    borderTop: "3px solid var(--light-theme-divider-borders-alt-1, #cccccc)",
    borderBottom: "3px solid var(--light-theme-divider-borders-alt-1, #cccccc)",
  };
  const customAccordion1Style = {
    border: "none", // Remove the border style
    boxShadow: "none", // Remove the box shadow
    borderTop: "3px solid var(--light-theme-divider-borders-alt-1, #cccccc)",
  };

  const customlastaccordionStyle = {
    border: "none", // Remove the border style
    boxShadow: "none", // Remove the box shadow
    // borderBottom: "3px solid var(--light-theme-divider-borders-alt-1, #cccccc)",
    borderTop: "3px solid var(--light-theme-divider-borders-alt-1, #cccccc)",
  };
  return (
    <div>
      <div className="needhelp-accordion ">
        <div className="needhelp-spacing">
          <div className="needhelp-spacer"></div>
        </div>
        <div className="needhelp-accordion-container">
          <Accordion sx={customAccordion1Style} className="needhelp-accordion2">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <div className="needhelp-what-is-ge-force-now">
                  What is NVIDIA Application Hub?
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="needhelp-accordiodetails-description">
                  {" "}
                  A: NVIDIA Application Hub is your central portal to access
                  NVIDIA Licensing Portal, NVIDIA Enterprise Support, NVIDIA
                  NGC, and NVIDIA Partner Portal
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={customAccordion1Style} className="needhelp-accordion2">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <div className="needhelp-what-is-ge-force-now">
                  What is NVIDIA ENTERPRISE Account?
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="needhelp-accordiodetails-description">
                  {" "}
                  A: An NVIDIA ENTERPRISE Account is a single sign-on and user
                  identity account that allows Enterprise customers to log in to
                  various NVIDIA applications with a single email address and
                  password. You can administer your NVIDIA ENTERPRISE Account
                  at {" "}
                  <a class="needhelp-link" href="https://nvid.nvidia.com/">
                    nvid.nvidia.com.
                  </a>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={customAccordion1Style} className="needhelp-accordion2">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>
                <div className="needhelp-what-is-ge-force-now">
                  How can I get an NVIDIA ENTERPRISE Account?
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="needhelp-accordiodetails-description">
                  <p>
                    A: You can get an NVIDIA ENTERPRISE Account by purchasing
                    an NVIDIA Licensed Product. To purchase NVIDIA software,
                    please contact your current NVIDIA partner or one of our
                    NVIDIA Partner Network (NPN) partners at{" "}
                    <a
                      class="needhelp-link"
                      href="https://www.nvidia.com/en-us/about-nvidia/partners/"
                    >
                      www.nvidia.com/en-us/about-nvidia/partners/
                    </a>{" "}
                    or your NVIDIA sales representative. The complete list of
                    partners can be found at{" "}
                    <a
                      class="needhelp-link"
                      href="https://www.nvidia.com/en-us/about-nvidia/partners/"
                    >
                      www.nvidia.com/object/partner-locator.html.
                    </a>
                  </p>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={customAccordion1Style} className="needhelp-accordion2">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <div className="needhelp-what-is-ge-force-now">
                  Can I create an account using a distribution list?
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="needhelp-accordiodetails-description">
                  A: You cannot create an account in the new NVIDIA identity
                  management system using a distribution list. The email address
                  verification step will fail. Please use your individual
                  corporate email address to register.
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={customAccordion1Style} className="needhelp-accordion2">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography>
                <div className="needhelp-what-is-ge-force-now">
                  What account should I register for if I am a 3rd party service
                  provider or system integrator for the end customer?
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="needhelp-accordiodetails-description">
                  A: Please ensure the intended end customer is registered for
                  this entitlement. 3rd party service providers or system
                  integrators should register the end customer using the end
                  customers’ information, including company name, email address
                  and address. Select “System Integrator” for the drop-down
                  value of “Claiming entitlement as” field during registration.
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={customAccordion1Style} className="needhelp-accordion2">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography>
                <div className="needhelp-what-is-ge-force-now">
                  Why am I being asked to create an account?
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="needhelp-accordiodetails-description">
                  <p>
                    A: On 16th December 2023, NVIDIA unified its identity
                    systems to improve customer experience. You need to create
                    an account in the new identity management system and the
                    system needs email verification, and a new password.
                  </p>
                  <br></br>{" "}
                  <p>
                    Your access to NVIDIA Licensing Portal, NVIDIA Enterprise
                    Support, NVIDIA NGC, and NVIDIA Partner Portal remains
                    unaffected.{" "}
                  </p>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={customAccordion1Style} className="needhelp-accordion2">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography>
                <div className="needhelp-what-is-ge-force-now">
                  How do I reset my password?
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="needhelp-accordiodetails-description">
                  A: On password prompt screen, navigate to Login Help page to
                  reset your password.
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={customAccordion1Style} className="needhelp-accordion2">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5a-content"
              id="panel5a-header"
            >
              <Typography>
                <div className="needhelp-what-is-ge-force-now">
                  I clicked on the 'Forgot Password’ button, but I did not
                  receive a reset password email.
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="needhelp-accordiodetails-description">
                  A: If your account exists, a reset email will be sent to the
                  email address on file. Please check your spam folder and safe
                  sender list.
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={customAccordion1Style} className="needhelp-accordion2">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6a-content"
              id="panel6a-header"
            >
              <Typography>
                <div className="needhelp-what-is-ge-force-now">
                  I received a 4001 error while I submitted my registration.
                  What went wrong?
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="needhelp-accordiodetails-description">
                  A: 4001 error means PAK ID/Entitlement ID field contained an
                  invalid value. If you purchased this entitlement through an
                  OEM partner and received instructions via a special locker,
                  then please enter the sales order number from your OEM partner
                  into the PAK ID/Entitlement ID field on the NVIDIA Enterprise
                  Account Registration page. If you were entering the correct
                  OEM sales order number when you received this error, then it's
                  possible that the order processing has not been completed yet
                  on the NVIDIA side for your entitlement. Please contact your
                  OEM partner representative.{" "}
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={customlastaccordionStyle}
            className="needhelp-accordion2"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7a-content"
              id="panel7a-header"
            >
              <Typography>
                <div className="needhelp-what-is-ge-force-now">
                  What do I do if I receive an error that my email id is
                  associated with a different account, during registration?
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="needhelp-accordiodetails-description">
                  A: This error means that, in our systems your email id is
                  associated with a different customer record than the
                  entitlement you tried registering against. Please contact
                  NVIDIA Enterprise Support, as it appears that we may need to
                  make some data adjustments to get this error fixed.
                </div>
              </Typography>
              <Typography>
                <div className="needhelp-accordiodetails-description">
                  If you are a System Integrator, contact or a Managed Services
                  Provider contact who is trying to register for your customer’s
                  entitlement, please note that you will need a unique email id
                  for each customer to register for that customer’s entitlement.
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={customlastaccordionStyle}
            className="needhelp-accordion2"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7a-content"
              id="panel7a-header"
            >
              <Typography>
                <div className="needhelp-what-is-ge-force-now">
                  What if I received an email saying I would hear back from
                  NVIDIA, but I never heard back?
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="needhelp-accordiodetails-description">
                  A: First, please check your Spam or Junk folders for this
                  email from NVIDIA. Also, you can contact your IT department to
                  modify email settings to allow system-generated emails coming
                  from @NVIDIA.com domain to be received. After following these
                  steps, if you are still having problems, please contact{" "}
                  <a
                    class="needhelp-link"
                    href="https://www.nvidia.com/en-us/support/enterprise/"
                  >
                    NVIDIA Enterprise Support
                  </a>{" "}
                  (call or use Web Form).
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={customlastaccordionStyle}
            className="needhelp-accordion2"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7a-content"
              id="panel7a-header"
            >
              <Typography>
                <div className="needhelp-what-is-ge-force-now">
                  What do I do if I see a message that indicates that my account
                  is under review while downloading NVIDIA software?
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="needhelp-accordiodetails-description">
                  A: This error message indicates that either your account or
                  the user information is under compliance review. This error
                  will normally be cleared in 24 hours, but for further
                  assistance contact{" "}
                  <a
                    class="needhelp-link"
                    href="https://www.nvidia.com/en-us/support/enterprise/"
                  >
                    NVIDIA Enterprise Support.
                  </a>{" "}
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={customlastaccordionStyle}
            className="needhelp-accordion2"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7a-content"
              id="panel7a-header"
            >
              <Typography>
                <div className="needhelp-what-is-ge-force-now">
                  As an NPN Partner, how can I register for the NPN Portal?
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="needhelp-accordiodetails-description">
                  A: At the{" "}
                  <a
                    class="needhelp-link"
                    href="https://www.nvidia.com/en-us/about-nvidia/partners/self-registration/"
                  >
                    NPN Partner Portal Self Registration Page
                  </a>{" "}
                  , you can use your approved company email address to register.
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={customlastaccordionStyle}
            className="needhelp-accordion2"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7a-content"
              id="panel7a-header"
            >
              <Typography>
                <div className="needhelp-what-is-ge-force-now">
                  I submitted an NPN portal self-registration form, but I did
                  not receive log in credentials email.
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="needhelp-accordiodetails-description">
                  A: Thank you for your submission. Please allow 30 minutes for
                  the systems to sync.
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={customlastaccordionStyle}
            className="needhelp-accordion2"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7a-content"
              id="panel7a-header"
            >
              <Typography>
                <div className="needhelp-what-is-ge-force-now">
                  I'm having a problem with my EVALUATION Account registration.
                  How do I get help?{" "}
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="needhelp-accordiodetails-description">
                  <p>
                    {" "}
                    If your question is about vGPU eval, then please refer to
                    the vGPU Evaluation{" "}
                    <a
                      class="needhelp-link"
                      href="https://www.nvidia.com/en-us/nvidia-enterprise-account-faq/"
                    >
                      FAQ.
                    </a>{" "}
                  </p>
                  <p>
                    For questions about NVIDIA AI Enterprise eval, please refer
                    to the Registration{" "}
                    <a
                      class="needhelp-link"
                      href="https://www.nvidia.com/en-us/nvidia-enterprise-account-faq/"
                    >
                      FAQ.
                    </a>{" "}
                  </p>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={customlastaccordionStyle}
            className="needhelp-accordion2"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7a-content"
              id="panel7a-header"
            >
              <Typography>
                <div className="needhelp-what-is-ge-force-now">
                  Where can I download the NVIDIA software and drivers?
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="needhelp-accordiodetails-description">
                  A: The registration approval email will include directions on
                  how to access your account and our licensing portal. If you’ve
                  already registered and set up your account, you can log in at{" "}
                  <a
                    class="needhelp-link"
                    href="https://nvid.nvidia.com/dashboard/"
                  >
                    nvid.nvidia.com/dashboard/
                  </a>{" "}
                  . For NVIDIA AI Enterprise, the AI tools, frameworks, and
                  pretrained models can be found in NVIDA NGC ({" "}
                  <a class="needhelp-link" href="https://ngc.nvidia.com/signin">
                    ngc.nvidia.com/signin
                  </a>{" "}
                  ). The registration approval email will also include
                  instructions on how to access NVIDIA NGC.
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={customlastaccordionStyle}
            className="needhelp-accordion2"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7a-content"
              id="panel7a-header"
            >
              <Typography>
                <div className="needhelp-what-is-ge-force-now">
                  Where can I find more information on NVIDIA software solution?
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="needhelp-accordiodetails-description">
                  A: Please visit{" "}
                  <a
                    class="needhelp-link"
                    href="https://docs.nvidia.com/ai-enterprise/index.html"
                  >
                    https://docs.nvidia.com/ai-enterprise/index.html
                  </a>{" "}
                  for further information on NVIDIA AI Enterprise. Please visit{" "}
                  <a class="needhelp-link" href="https://docs.nvidia.com/grid/">
                    https://docs.nvidia.com/grid/
                  </a>{" "}
                  for further information on vGPU Solution software.
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={customlastaccordionStyle}
            className="needhelp-accordion2"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7a-content"
              id="panel7a-header"
            >
              <Typography>
                <div className="needhelp-what-is-ge-force-now">
                  What do I do if I don’t see the latest NVIDIA SW packages that
                  I wish to download?
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="needhelp-accordiodetails-description">
                  A: If you are seeing prior versions of the NVIDIA AI
                  Enterprise or vGPU Software packages but not the latest
                  software version, that is most likely because your
                  subscriptions or SUMS have expired and have not been renewed.
                  You will need to renew your NVIDIA AI Enterprise or vGPU
                  subscription or SUMS first before you can download the latest
                  software version. Or you can download the prior vGPU Software
                  versions that are visible on the NVIDIA Licensing Portal.
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={customAccordionStyle} className="needhelp-accordion2">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7a-content"
              id="panel7a-header"
            >
              <Typography>
                <div className="needhelp-what-is-ge-force-now">
                  {" "}
                  I have reviewed this FAQ. My question was not noted in this
                  FAQ. What can I do?
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="needhelp-accordiodetails-description">
                  {" "}
                  A: Please contact{" "}
                  <a
                    class="needhelp-link"
                    href="https://www.nvidia.com/en-us/support/enterprise/"
                  >
                    NVIDIA Enterprise Support.
                  </a>{" "}
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="needhelp-spacing">
          <div className="needhelp-spacer-bottom"></div>
        </div>
      </div>
    </div>
  );
}
