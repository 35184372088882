import React from "react";
import PermissionList from "../pages/permission";
import PermissionDetail from "../pages/permission/[id]";
import ApplicationList from "../pages/application";
import Dashboard from "../pages/dashboard/index";
import ApplicationDetail from "../pages/application/[id]";
import UserList from "../pages/user";
import UserDetail from "../pages/user/[id]";
import RoleList from "../pages/role";
// import AdUserList from "../pages/aduser";
import AuditLogList from "../pages/auditlog";
import AuditLogDetail from "../pages/auditlog/[id]";
import UserAuditLog from "../pages/userauditlog/[email]";
import RoleDetail from "../pages/role/[id]";
import UnAuthorized from "../pages/Errors/UnAuthorized";
import ServerError from "../pages/Errors/ServerError";
import InternalServerError from "../pages/Errors/InternalServerError";
import Login from "../pages/login/Login";
import SecureLogin from "../pages/securelogin";
import Logout from "../pages/logout";
import NeedHelp from "../pages/needhelp";
import NotFound from "../pages/Errors/NotFound";
import UnAuthorizedError from "../pages/Errors/UnAuthorizedError";
import UserError from "../pages/Errors/UserError";
import UserNotFound from "../pages/Errors/UserNotFound";

import AuthError from "../pages/Errors/AuthError";
import BulkUserUpload from "../pages/bulkuserupload";
import BulkUploadMapping from "../pages/bulkuploadmapping";
import BulkRoleUpdate from "../pages/bulkroleupdate";
const routes = [
  {
    path: "/",
    component: <Login />,
  },
  {
    exact: true,
    path: "/logout",
    component: <Logout />,
  },
  {
    exact: true,
    path: "/login",
    component: <Login />,
  },
  {
    exact: true,
    path: "/callback",
    component: <Login />,
  },
  {
    exact: true,
    path: "/index.html",
    component: <Login />,
  },
  {
    exact: true,
    path: "/espContent/index.html",
    component: <Login />,
  },
  {
    exact: true,
    path: "/sfdcContent/index.html",
    component: <Login />,
  },
  {
    exact: true,
    path: "/securelogin",
    component: <SecureLogin />,
  },
  {
    exact: true,
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    exact: true,
    path: "/application",
    component: <ApplicationList />,
  },
  {
    exact: true,
    path: "/application/:id",
    component: <ApplicationDetail />,
  },
  {
    exact: true,
    path: "/permission",
    component: <PermissionList />,
  },
  {
    exact: true,
    path: "/permission/:id",
    component: <PermissionDetail />,
  },
  {
    exact: true,
    path: "/user",
    component: <UserList />,
  },
  {
    exact: "true",
    path: "/user/:id",
    component: <UserDetail />,
  },
  {
    exact: "true",
    path: "/userauditlog/:email",
    component: <UserAuditLog />,
  },
  {
    exact: "true",
    path: "/role",
    component: <RoleList />,
  },

  {
    exact: "true",
    path: "/role/:id",
    component: <RoleDetail />,
  },
  {
    exact: "true",
    path: "/auditlog",
    component: <AuditLogList />,
  },
  {
    exact: "true",
    path: "/needhelp",
    component: <NeedHelp />,
  },
  {
    exact: "true",
    path: "/auditlog/:id",
    component: <AuditLogDetail />,
  },
  {
    exact: true,
    path: "/logout",
    component: <Logout />,
  },
  {
    exact: true,
    path: "/unauthorized",
    component: <UnAuthorized />,
  },
  {
    exact: true,
    path: "/unauthorizederror",
    component: <UnAuthorizedError />,
  },
  {
    exact: true,
    path: "/servererror",
    component: <ServerError />,
  },
  {
    exact: true,
    path: "/internalservererror",
    component: <InternalServerError />,
  },
  {
    exact: true,
    path: "/usererror",
    component: <UserError />,
  },
  {
    exact: true,
    path: "/usernotfound",
    component: <UserNotFound />,
  },
  {
    exact: "true",
    path: "/bulkuserupload",
    component: <BulkUserUpload />,
  },
  {
    exact: true,
    path: "/autherror",
    component: <AuthError />,
  },
  {
    exact: false,
    path: "*",
    component: <NotFound />,
  },
  {
    exact: "true",
    path: "/bulkuploadmapping",
    component: <BulkUploadMapping />,
  },
  {
    exact: "true",
    path: "/bulkroleupdate",
    component: <BulkRoleUpdate />,
  },
];

export default routes;
