import React, { useContext, useCallback, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import GlobalFooter from "../../components/globalfooter";
import PageFooter from "../../components/pagefooter";
import Header from "../../components/header";
import Background from "../../static/images/nvod-web-ondemand-hero.png";
import "./styles.css";
import "../../styles/Loader.css";
import ApplicationHub from "../../components/applicationhub/ApplicationHub";

import {
  fetchApplicationsByUser,
  fetchUserDetailsByEmail,
  // isTokenExpiredasync,
} from "../../api";

import WithAuth from "../../components/auth/WithAuth";
import { writeUser } from "../../common/loginutils";
// import Button from "@kaizen-ui/button";
import {
  renewAccessTokenCurrentUser,
  isTokenExpiredasync,
} from "../../common/renewaccesstoken";
const Dashboard = () => {
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [userName, setUserName] = useState([]);

  const [userDependenciesLoaded, setUserDependenciesLoaded] = useState(false);
  const [userData, setUserData] = useState(false);
  const supportUrl = "https://support-test.nvidia.com/";

  useEffect(() => {
    (async () => {
      // After successful login, redirect to the referrer URL if it exists
      const referrerUrl = localStorage.getItem("referrerUrl");

      if (
        referrerUrl &&
        referrerUrl !== "" &&
        referrerUrl.includes(supportUrl)
      ) {
        // Redirect to the referrer URL
        window.location.href = referrerUrl;
      }

      let email = localStorage.getItem("email");
      if (
        email === "" ||
        email === null ||
        email === undefined ||
        email === "undefined"
      ) {
        localStorage.clear();
        localStorage.clear();

        navigate("/login");
      }

      var isTokenExpired = await isTokenExpiredasync();
      if (
        isTokenExpired &&
        localStorage.getItem("TokenExpiredPage") !== null &&
        localStorage.getItem("TokenExpiredPage") !== undefined
      ) {
        localStorage.setItem("TokenExpiredPage", window.location.pathname);
        await renewAccessTokenCurrentUser();
      }

      let heading = "";
      const token = localStorage.getItem("ROCP_idToken");

      if (
        email !== "" &&
        email !== null &&
        email !== undefined &&
        token !== "" &&
        token !== null &&
        token !== undefined
      ) {
        let data;

        let appsresponse;
        if (
          localStorage.getItem("UserDetails") !== undefined &&
          localStorage.getItem("UserDetails") !== "" &&
          localStorage.getItem("UserDetails") !== "undefined" &&
          localStorage.getItem("UserDetails") !== null
        ) {
          data = JSON.parse(localStorage.getItem("UserDetails"));
        } else {
          localStorage.removeItem("UserDetails");
          localStorage.removeItem("ApplicationDetails");
        }

        if (
          localStorage.getItem("ApplicationDetails") !== undefined &&
          localStorage.getItem("ApplicationDetails") !== "undefined" &&
          localStorage.getItem("ApplicationDetails") !== "" &&
          localStorage.getItem("ApplicationDetails") !== null
        ) {
          appsresponse = JSON.parse(localStorage.getItem("ApplicationDetails"));
        } else {
          localStorage.removeItem("UserDetails");
          localStorage.removeItem("ApplicationDetails");
        }

        if (
          data !== "" &&
          data !== null &&
          data !== undefined &&
          appsresponse !== null &&
          appsresponse !== undefined
        ) {
          setUserDependenciesLoaded(true);
          setUserData(true);
          heading = "Welcome " + data?.firstname + " " + data?.lastname;
          setUserName(heading);

          let apps = appsresponse;
          setItems(apps);
          const referrerUrl = localStorage.getItem("referrerUrl");

          if (
            referrerUrl &&
            referrerUrl !== "" &&
            referrerUrl.includes(supportUrl)
          ) {
            // Redirect to the referrer URL
            window.location.href = referrerUrl;
          } else if (apps !== null && apps.length && apps.length === 1) {
            let application = apps[0];
            let searchParams = new URLSearchParams(window.location.search);
            if (searchParams.get("noautoredirect")) {
              let code = decodeURIComponent(searchParams.get("noautoredirect"));
              if (code !== "true" && code !== true) {
                window.location.href = application.applicationurl;
              }
            } else {
              //If user has only NGC Portal Access Write details in iframe
              if (application.applicationname.toUpperCase() === "NVIDIA NGC") {
                if (
                  localStorage.getItem("idp_id") !== "" &&
                  localStorage.getItem("idp_id") !== null &&
                  localStorage.getItem("idp_id") !== undefined &&
                  localStorage.getItem("sub") !== "" &&
                  localStorage.getItem("sub") !== null &&
                  localStorage.getItem("sub") !== undefined
                ) {
                  writeUser(
                    localStorage.getItem("email"),
                    localStorage.getItem("idp_id"),
                    localStorage.getItem("expire_at"),
                    localStorage.getItem("sub")
                  );
                }
              }

              window.location.href = application.applicationurl;
            }
          }
        } else {
          data = await fetchUserDetailsByEmail();
          if (data.statusCode === 404) {
            setUserData(false);
            navigate("/usererror");
          }
          if (data.statusCode === 401 || data.statusCode === 500) {
            localStorage.clear();
            localStorage.clear();
            navigate("/login");
          }
          if (data.statusCode === 200) {
            var res = data.data;
            localStorage.setItem("UserDetails", JSON.stringify(res.data));
            setUserDependenciesLoaded(true);

            if (res.isactive === false) {
              setUserData(false);
              navigate("/usererror");
            } else if (res.earrestricted === true) {
              setUserData(false);
              navigate("/usererror");
            } else {
              setUserData(true);
              heading = "Welcome " + res?.firstname + " " + res?.lastname;
              setUserName(heading);

              let resdata = await fetchApplicationsByUser();
              if (resdata.statusCode === 401 || data.statusCode === 500) {
                localStorage.clear();
                localStorage.clear();
                navigate("/login");
              }
              if (resdata.statusCode === 200) {
                let apps = resdata.data;
                setItems(apps);
                localStorage.setItem(
                  "ApplicationDetails",
                  JSON.stringify(resdata.data)
                );
                if (apps !== null && apps.length && apps.length === 1) {
                  let application = apps[0];
                  let searchParams = new URLSearchParams(
                    window.location.search
                  );
                  if (searchParams.get("noautoredirect")) {
                    let code = decodeURIComponent(
                      searchParams.get("noautoredirect")
                    );
                    if (code !== "true" && code !== true) {
                      window.location.href = application.applicationurl;
                    }
                  } else {
                    window.location.href = application.applicationurl;
                  }
                }
              }
            }
          }
        }
      } else {
        localStorage.clear();
        localStorage.clear();
        navigate("/login");
      }
    })();
  }, [navigate]);

  const handleClick = (url, appname) => (e) => {
    if (appname.toUpperCase() === "NVIDIA NGC") {
      if (
        localStorage.getItem("idp_id") !== "" &&
        localStorage.getItem("idp_id") !== null &&
        localStorage.getItem("idp_id") !== undefined &&
        localStorage.getItem("sub") !== "" &&
        localStorage.getItem("sub") !== null &&
        localStorage.getItem("sub") !== undefined
      ) {
        writeUser(
          localStorage.getItem("email"),
          localStorage.getItem("idp_id"),
          localStorage.getItem("expire_at"),
          localStorage.getItem("sub")
        );
      }
    }
    //window.open(url, "_blank");
    window.open(url, "_blank");
    e.preventDefault();
  };

  // const handlerenClick = () => {
  //   renewAccessTokenCurrentUser();
  // };
  return (
    <div>
      {!userDependenciesLoaded ? (
        <div className="loader-container">
          {" "}
          <div className="loader"></div>
        </div>
      ) : userData ? (
        <div>
          <Header
            headerHeading={userName}
            loginHeader={false}
            dashboardHeader={true}
          ></Header>

          <div
            className="nvod-web-ondemand-hero-bb-460-420-l-2"
            style={{
              backgroundImage: `url(${Background})`,
              backgroundSize: "100% auto",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="application-hub-brand-navigation">
              <div className="brand-nav">
                <div className="header-container2">
                  <div className="header-frame-39">
                    <div
                      style={{ padding: "20px 30px" }}
                      className="dashboard-laptop-heading-huge"
                    >
                      NVIDIA Application Hub
                      <br></br>
                      <div className="dashboard-laptop-text-large">
                        Access the tools and services you need quickly and
                        easily.
                      </div>
                    </div>
                  </div>
                  <div className="header-button-container"></div>
                </div>
              </div>
            </div>
          </div>

          {/* <div class="application-hub">
            <div
              style={{
                backgroundImage: `url(${Background})`,
                backgroundSize: "100% auto",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="dashboard-heading">
                <div
                  style={{ marginTop: "30px" }}
                  className="dashboard-laptop-heading-huge"
                >
                  NVIDIA Application Hub
                </div>
              </div>
              <div className="dashboard-text">
                <div className="dashboard-laptop-text-large">
                  Access the tools and services you need quickly and easily.
                </div>
              </div>
            </div>
          </div> */}

          <div style={{ minHeight: "100px" }}>
            <ApplicationHub
              items={items}
              ClickEvent={handleClick}
            ></ApplicationHub>
          </div>
          {/* <Button
            key="btncreateresponse"
            icon={{ name: "ActionsCircleAdd", variant: "solid" }}
            onClick={() => {
              handlerenClick();
            }}
          ></Button> */}
          <PageFooter></PageFooter>
          <GlobalFooter></GlobalFooter>
        </div>
      ) : (
        <div>
          <Header
            headerHeading="NVIDIA Application Hub"
            loginHeader={true}
            dashboardHeader={false}
          ></Header>
          <div className="centered-message-app">
            <div className="centered-message">
              <p>
                {" "}
                Not a valid user. Please visit{" "}
                <Link
                  className="sign-in-text sign-in-text-medium"
                  to="/needhelp"
                >
                  Need help?
                </Link>{" "}
                for more information.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default WithAuth(Dashboard);
